/**
 * @generated SignedSource<<314acfc36f304f3da203efaf1cc92f00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateAdminPreferencesInput = {
  preferences: AdminPreferencesInput;
};
export type AdminPreferencesInput = {
  language?: string | null;
};
export type Profile_updateAdminPreferences_Mutation$variables = {
  input: UpdateAdminPreferencesInput;
};
export type Profile_updateAdminPreferences_Mutation$data = {
  readonly updateAdminPreferences: {
    readonly admin: {
      readonly preferences: {
        readonly language: string;
      };
    };
  } | null;
};
export type Profile_updateAdminPreferences_Mutation = {
  response: Profile_updateAdminPreferences_Mutation$data;
  variables: Profile_updateAdminPreferences_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AdminPreferences",
  "kind": "LinkedField",
  "name": "preferences",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Profile_updateAdminPreferences_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdminPreferencesPayload",
        "kind": "LinkedField",
        "name": "updateAdminPreferences",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Admin",
            "kind": "LinkedField",
            "name": "admin",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Profile_updateAdminPreferences_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdminPreferencesPayload",
        "kind": "LinkedField",
        "name": "updateAdminPreferences",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Admin",
            "kind": "LinkedField",
            "name": "admin",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "10f3ffc0469c1b89cc9ef83a6a0bd395",
    "id": null,
    "metadata": {},
    "name": "Profile_updateAdminPreferences_Mutation",
    "operationKind": "mutation",
    "text": "mutation Profile_updateAdminPreferences_Mutation(\n  $input: UpdateAdminPreferencesInput!\n) {\n  updateAdminPreferences(input: $input) {\n    admin {\n      preferences {\n        language\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1cebb8f55ad2f732a23a27f1eaed07ec";

export default node;
