import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { graphql, useFragment } from "react-relay";

import type {
  UserFieldsTable_UserFieldFragment$data,
  UserFieldsTable_UserFieldFragment$key,
} from "api/__generated__/UserFieldsTable_UserFieldFragment.graphql";
import Table from "components/Table";
import type { Column } from "components/Table";
import { Link, Route } from "Navigation";

// We use graphql fields below in columns configuration
/* eslint-disable relay/unused-fields */
const USER_FIELDS_TABLE_FRAGMENT = graphql`
  fragment UserFieldsTable_UserFieldFragment on UserCustomField
  @relay(plural: true) {
    id
    name
  }
`;

type UserFieldProps = UserFieldsTable_UserFieldFragment$data[0];

type UseColumnsProps = {
  tenantId: string;
};

const useColumns = ({
  tenantId,
}: UseColumnsProps): Column<UserFieldProps>[] => [
  {
    accessor: "name",
    Header: (
      <FormattedMessage
        id="components.UserFieldsTable.name.title"
        defaultMessage="Name"
      />
    ),
    Cell: ({ row, value }) => (
      <Link
        route={Route.userFieldsEdit}
        params={{ tenantId, userFieldId: row.original.id }}
      >
        {value}
      </Link>
    ),
  },
];

interface Props {
  className?: string;
  searchText?: string;
  tenantId: string;
  userFieldsRef: UserFieldsTable_UserFieldFragment$key;
}

const UserFieldsTable = ({
  className,
  searchText,
  tenantId,
  userFieldsRef,
}: Props) => {
  const columns = useColumns({ tenantId });

  const userFieldsData = useFragment(USER_FIELDS_TABLE_FRAGMENT, userFieldsRef);

  // TODO: handle readonly type without mapping to mutable type
  const userFields = useMemo(
    () => userFieldsData.map((userField) => ({ ...userField })),
    [userFieldsData]
  );
  return (
    <Table
      className={className}
      columns={columns}
      data={userFields}
      searchText={searchText}
    />
  );
};

export default UserFieldsTable;
