import React from "react";
import { FormattedMessage } from "react-intl";

import Table from "components/Table";
import type { Column } from "components/Table";
import Tag from "components/Tag";
import { Link, Route } from "Navigation";

type CapabilitySet = {
  name: string;
};

type Data = {
  id: string;
  name: string;
  thin: boolean;
  domain: string | null;
  capabilitySets: CapabilitySet[];
};

type UseColumnsProps = {
  tenantId: string;
};

const useColumns = ({ tenantId }: UseColumnsProps): Column<Data>[] => [
  {
    accessor: "name",
    Header: (
      <FormattedMessage
        id="components.OrganizationsTable.name.title"
        defaultMessage="Name"
      />
    ),
    Cell: ({ row, value }) => (
      <Link
        route={Route.organizationEdit}
        params={{ tenantId, organizationId: row.original.id }}
      >
        {value}
      </Link>
    ),
  },
  {
    accessor: "thin",
    disableSortBy: true,
    Header: (
      <FormattedMessage
        id="components.OrganizationsTable.type.title"
        defaultMessage="Type"
      />
    ),
    Cell: ({ value }) =>
      value === true ? (
        <FormattedMessage
          id="components.OrganizationsTable.type.thin"
          defaultMessage="Thin"
        />
      ) : (
        <FormattedMessage
          id="components.OrganizationsTable.type.full"
          defaultMessage="Full"
        />
      ),
  },
  {
    accessor: "domain",
    Header: (
      <FormattedMessage
        id="components.OrganizationsTable.domain.title"
        defaultMessage="Domain"
      />
    ),
  },
  {
    accessor: "capabilitySets",
    disableSortBy: true,
    Header: (
      <FormattedMessage
        id="components.OrganizationsTable.capabilitySets.title"
        defaultMessage="Capability Sets"
      />
    ),
    Cell: ({ row, value }) =>
      value.map((capabilitySet) => (
        <Tag key={capabilitySet.name} className="me-2">
          {capabilitySet.name}
        </Tag>
      )),
  },
];

interface Props {
  className?: string;
  data: Data[];
  searchText?: string;
  tenantId: string;
}

const OrganizationsTable = ({
  className,
  data,
  searchText,
  tenantId,
}: Props) => {
  const columns = useColumns({ tenantId });
  return (
    <Table
      className={className}
      columns={columns}
      data={data}
      searchText={searchText}
    />
  );
};

export default OrganizationsTable;
