import React from "react";
import Form from "react-bootstrap/Form";
import { useIntl, defineMessages } from "react-intl";

const supportedApplicationScopes = ["GLOBAL", "DATA", "APPLIANCE"] as const;

type SupportedApplicationScope = typeof supportedApplicationScopes[number];

const applicationScopeMessages = defineMessages<SupportedApplicationScope>({
  APPLIANCE: {
    id: "application.scope.appliance",
    defaultMessage: "Appliance",
  },
  DATA: {
    id: "application.scope.data",
    defaultMessage: "Data",
  },
  GLOBAL: {
    id: "application.scope.global",
    defaultMessage: "Global",
  },
});

const isSupportedApplicationScope = (
  scope: string
): scope is SupportedApplicationScope => {
  for (const supportedAppScope of supportedApplicationScopes) {
    if (supportedAppScope === scope) {
      return true;
    }
  }
  return false;
};

type Props = React.ComponentProps<typeof Form.Select> & {
  value: SupportedApplicationScope;
};

const ApplicationScopeInput = (props: Props) => {
  const intl = useIntl();

  return (
    <Form.Select {...props}>
      <option value="" disabled>
        {intl.formatMessage({
          id: "components.ApplicationScopeInput.selectAppScopePrompt",
          defaultMessage: "Scope",
          description: "Prompt to select the scope of an application",
        })}
      </option>
      {supportedApplicationScopes.map((scope) => (
        <option key={scope} value={scope}>
          {intl.formatMessage(applicationScopeMessages[scope])}
        </option>
      ))}
    </Form.Select>
  );
};

export type { SupportedApplicationScope };

export { applicationScopeMessages, isSupportedApplicationScope };

export default ApplicationScopeInput;
