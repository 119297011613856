import { useMemo } from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay/hooks";
import { FormattedDate, FormattedMessage } from "react-intl";
import type {
  UserInvitesTable_userInvites$key,
  UserInvitesTable_userInvites$data,
} from "api/__generated__/UserInvitesTable_userInvites.graphql";

import Button from "components/Button";
import Icon from "components/Icon";
import Table, { Column } from "components/Table";

const USER_INVITES_FRAGMENT = graphql`
  fragment UserInvitesTable_userInvites on Organization {
    userInvites {
      sentTo
      createdAt
    }
  }
`;

type Data = UserInvitesTable_userInvites$data["userInvites"][number];

type UseColumnsProps = {
  onDeleteUserInvites: (emails: string[]) => void;
  tenantId: string;
};

const useColumns = ({
  tenantId,
  onDeleteUserInvites,
}: UseColumnsProps): Column<Data>[] => [
  {
    accessor: "sentTo",
    Header: (
      <FormattedMessage
        id="components.UserInvitesTable.sentTo.title"
        defaultMessage="Sent To"
      />
    ),
  },
  {
    accessor: "createdAt",
    Header: (
      <FormattedMessage
        id="components.UserInvitesTable.createdAt.title"
        defaultMessage="Created At"
      />
    ),
    Cell: ({ value }) => (
      <FormattedDate
        value={new Date(value)}
        year="numeric"
        month="long"
        day="numeric"
        hour="numeric"
        minute="numeric"
      />
    ),
  },
  {
    id: "actions",
    accessor: "sentTo",
    Cell: ({ row }) => (
      <Button
        variant="danger"
        onClick={() => onDeleteUserInvites([row.original.sentTo])}
      >
        <Icon icon="delete" />
      </Button>
    ),
  },
];

interface Props {
  className?: string;
  userInvitesRef: UserInvitesTable_userInvites$key;
  onDeleteUserInvites: (emails: string[]) => void;
  searchText?: string;
  tenantId: string;
}

const UserInvitesTable = ({
  className,
  onDeleteUserInvites,
  userInvitesRef,
  searchText,
  tenantId,
}: Props) => {
  const columns = useColumns({ tenantId, onDeleteUserInvites });
  const userInvitesData = useFragment(USER_INVITES_FRAGMENT, userInvitesRef);
  const userInvites = useMemo(
    () =>
      userInvitesData.userInvites.map((userInvite) => ({
        ...userInvite,
      })),
    [userInvitesData]
  );

  if (userInvites.length === 0) {
    return (
      <FormattedMessage
        id="components.UserInvitesTable.noInvites"
        defaultMessage="There are no invites."
      />
    );
  }

  return (
    <Table
      className={className}
      columns={columns}
      data={userInvites}
      searchText={searchText}
    />
  );
};

export default UserInvitesTable;
