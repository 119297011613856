/**
 * @generated SignedSource<<a1f1bee07efdaab5710e2401906fa6e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateOrganizationInput = {
  adminEmails: ReadonlyArray<string>;
  domain?: string | null;
  name: string;
  tenantId: string;
  thin?: boolean | null;
};
export type OrganizationCreate_createOrganization_Mutation$variables = {
  input: CreateOrganizationInput;
};
export type OrganizationCreate_createOrganization_Mutation$data = {
  readonly createOrganization: {
    readonly organization: {
      readonly domain: string | null;
      readonly id: string;
      readonly name: string;
      readonly thin: boolean;
      readonly userInvites: ReadonlyArray<{
        readonly createdAt: string;
        readonly sentTo: string;
      }>;
    };
  } | null;
};
export type OrganizationCreate_createOrganization_Mutation = {
  response: OrganizationCreate_createOrganization_Mutation$data;
  variables: OrganizationCreate_createOrganization_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateOrganizationPayload",
    "kind": "LinkedField",
    "name": "createOrganization",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "domain",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserInvite",
            "kind": "LinkedField",
            "name": "userInvites",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sentTo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationCreate_createOrganization_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationCreate_createOrganization_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "08487a7db7509e58a0dace1f640775be",
    "id": null,
    "metadata": {},
    "name": "OrganizationCreate_createOrganization_Mutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationCreate_createOrganization_Mutation(\n  $input: CreateOrganizationInput!\n) {\n  createOrganization(input: $input) {\n    organization {\n      id\n      name\n      thin\n      domain\n      userInvites {\n        sentTo\n        createdAt\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cafb23d1ee03bc7d8d5fcfe9d056785a";

export default node;
