/**
 * @generated SignedSource<<e8258d52d02a6b68656067aaa49add25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ApplicationScope = "APPLIANCE" | "DATA" | "GLOBAL" | "%future added value";
export type CreateApplicationInput = {
  application: ApplicationInput;
  tenantId: string;
};
export type ApplicationInput = {
  description?: string | null;
  displayName: string;
  protocol: string;
  requiredInterfaces: ReadonlyArray<InterfaceRequirementInput>;
  scope: ApplicationScope;
  slug: string;
  sourceUrl: string;
};
export type InterfaceRequirementInput = {
  majorVersion: number;
  minorVersion: number;
  name: string;
};
export type ApplicationCreate_createApplication_Mutation$variables = {
  input: CreateApplicationInput;
};
export type ApplicationCreate_createApplication_Mutation$data = {
  readonly createApplication: {
    readonly application: {
      readonly id: string;
    };
  } | null;
};
export type ApplicationCreate_createApplication_Mutation = {
  response: ApplicationCreate_createApplication_Mutation$data;
  variables: ApplicationCreate_createApplication_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateApplicationPayload",
    "kind": "LinkedField",
    "name": "createApplication",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Application",
        "kind": "LinkedField",
        "name": "application",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApplicationCreate_createApplication_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApplicationCreate_createApplication_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ba40b1769fe6c8b30915f612a14713d0",
    "id": null,
    "metadata": {},
    "name": "ApplicationCreate_createApplication_Mutation",
    "operationKind": "mutation",
    "text": "mutation ApplicationCreate_createApplication_Mutation(\n  $input: CreateApplicationInput!\n) {\n  createApplication(input: $input) {\n    application {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "95f5011f6f3bc202afa513f2de67dd8a";

export default node;
