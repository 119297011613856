import React, { useCallback, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay/hooks";
import type {
  UserFieldCreate_createUserField_Mutation,
  CreateUserCustomFieldInput,
} from "api/__generated__/UserFieldCreate_createUserField_Mutation.graphql";
import { useParams } from "react-router";
import { FormattedMessage } from "react-intl";
import Alert from "react-bootstrap/Alert";

import Button from "components/Button";
import UserFieldForm from "components/UserFieldForm";
import Spinner from "components/Spinner";
import Stack from "components/Stack";
import { Route, useNavigate } from "Navigation";

const CREATE_USER_FIELD_MUTATION = graphql`
  mutation UserFieldCreate_createUserField_Mutation(
    $input: CreateUserCustomFieldInput!
  ) {
    createUserCustomField(input: $input) {
      userCustomField {
        id
        name
        schema
      }
    }
  }
`;

interface FormData {
  name: string;
  schema: string | null;
}

const initialFormData: FormData = {
  name: "",
  schema: null,
};

const UserField = () => {
  const { tenantId = "" } = useParams();
  const [draft, setDraft] = useState({
    formData: initialFormData,
    isValid: false,
  });
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const navigate = useNavigate();

  const [createUserField, isCreatingUserField] =
    useMutation<UserFieldCreate_createUserField_Mutation>(
      CREATE_USER_FIELD_MUTATION
    );

  const handleSubmit = useCallback(() => {
    const input: CreateUserCustomFieldInput = { tenantId, ...draft.formData };
    createUserField({
      variables: { input },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          return setErrorFeedback(errorFeedback);
        }
        const userFieldId = data?.createUserCustomField?.userCustomField.id;
        if (userFieldId) {
          navigate({
            route: Route.userFieldsEdit,
            params: { tenantId, userFieldId },
          });
        } else {
          navigate({ route: Route.userFields, params: { tenantId } });
        }
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.UserFieldCreate.createErrorFeedback"
            defaultMessage="Could not create the user field, please try again."
            description="Feedback for unknown create error in the UserFieldCreate page"
          />
        );
      },
      updater(store) {
        const userField = store
          .getRootField("createUserCustomField")
          .getLinkedRecord("userCustomField");
        const tenant = store.get(tenantId);
        const userFields = tenant?.getLinkedRecords("userCustomFields");
        if (tenant && userFields) {
          tenant.setLinkedRecords(
            [...userFields, userField],
            "userCustomFields"
          );
        }
      },
    });
  }, [createUserField, draft, navigate, tenantId]);

  const canCreateUserField = !isCreatingUserField;

  return (
    <div className="py-4 px-5">
      <Stack gap={3}>
        <header className="d-flex justify-content-between align-items-center">
          <h2 className="text-muted">
            <FormattedMessage
              id="pages.UserFieldCreate.title"
              defaultMessage="Create User Field"
              description="Title for the UserFieldCreate page"
            />
          </h2>
          <div className="d-flex">
            <Button
              variant="primary"
              type="submit"
              form="user-field-form"
              disabled={!canCreateUserField}
            >
              {isCreatingUserField && <Spinner size="sm" className="me-2" />}
              <FormattedMessage
                id="pages.UserFieldCreate.form.createButton"
                defaultMessage="Create"
                description="Title for the button to create the user field in the UserFieldCreate page"
              />
            </Button>
          </div>
        </header>
        <Alert
          show={!!errorFeedback}
          variant="danger"
          onClose={() => setErrorFeedback(null)}
          dismissible
        >
          {errorFeedback}
        </Alert>
        <UserFieldForm
          id="user-field-form"
          value={draft.formData}
          onChange={(formData, isValid) => setDraft({ formData, isValid })}
          onSubmit={handleSubmit}
        />
      </Stack>
    </div>
  );
};

export default UserField;
