/**
 * @generated SignedSource<<04ce500bdf16d1511a5e8227803ed4d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Capability = "CAN_ADMIN_APPLIANCES" | "CAN_MANAGE_APPLIANCE_MODELS" | "%future added value";
export type UpdateCapabilitySetInput = {
  capabilities?: ReadonlyArray<Capability> | null;
  capabilitySetId: string;
  name?: string | null;
};
export type CapabilitySet_updateCapabilitySet_Mutation$variables = {
  input: UpdateCapabilitySetInput;
};
export type CapabilitySet_updateCapabilitySet_Mutation$data = {
  readonly updateCapabilitySet: {
    readonly capabilitySet: {
      readonly capabilities: ReadonlyArray<Capability>;
      readonly id: string;
      readonly name: string;
    };
  } | null;
};
export type CapabilitySet_updateCapabilitySet_Mutation = {
  response: CapabilitySet_updateCapabilitySet_Mutation$data;
  variables: CapabilitySet_updateCapabilitySet_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateCapabilitySetPayload",
    "kind": "LinkedField",
    "name": "updateCapabilitySet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CapabilitySet",
        "kind": "LinkedField",
        "name": "capabilitySet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "capabilities",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CapabilitySet_updateCapabilitySet_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CapabilitySet_updateCapabilitySet_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1df9a18426d09ccaf00baeb6285ff83a",
    "id": null,
    "metadata": {},
    "name": "CapabilitySet_updateCapabilitySet_Mutation",
    "operationKind": "mutation",
    "text": "mutation CapabilitySet_updateCapabilitySet_Mutation(\n  $input: UpdateCapabilitySetInput!\n) {\n  updateCapabilitySet(input: $input) {\n    capabilitySet {\n      id\n      name\n      capabilities\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "41be03ea37c7687bb28ff8c74da91489";

export default node;
