import React from "react";
import { FormattedMessage } from "react-intl";

import Table from "components/Table";
import type { Column } from "components/Table";
import { Link, Route } from "Navigation";

type Data = {
  id: string;
  name: string;
};

type UseColumnsProps = {
  tenantId: string;
};

const useColumns = ({ tenantId }: UseColumnsProps): Column<Data>[] => [
  {
    accessor: "name",
    Header: (
      <FormattedMessage
        id="components.CapabilitySetsTable.name.title"
        defaultMessage="Name"
      />
    ),
    Cell: ({ row, value }) => (
      <Link
        route={Route.capabilitySetEdit}
        params={{ tenantId, capabilitySetId: row.original.id }}
      >
        {value}
      </Link>
    ),
  },
];

interface Props {
  className?: string;
  data: Data[];
  searchText?: string;
  tenantId: string;
}

const CapabilitySetsTable = ({
  className,
  data,
  searchText,
  tenantId,
}: Props) => {
  const columns = useColumns({ tenantId });
  return (
    <Table
      className={className}
      columns={columns}
      data={data}
      searchText={searchText}
    />
  );
};

export default CapabilitySetsTable;
