import React, { useCallback, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay/hooks";
import type {
  OrganizationCreate_createOrganization_Mutation,
  CreateOrganizationInput,
} from "api/__generated__/OrganizationCreate_createOrganization_Mutation.graphql";
import { useParams } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import _ from "lodash";

import Button from "components/Button";
import MultiSelect from "components/MultiSelect";
import Spinner from "components/Spinner";
import Stack from "components/Stack";
import { Route, useNavigate } from "Navigation";

const CREATE_ORGANIZATION_MUTATION = graphql`
  mutation OrganizationCreate_createOrganization_Mutation(
    $input: CreateOrganizationInput!
  ) {
    createOrganization(input: $input) {
      organization {
        id
        name
        thin
        domain
        userInvites {
          sentTo
          createdAt
        }
      }
    }
  }
`;

interface FormData {
  name: string;
  thin: boolean;
  domain: string;
  adminEmails: string[];
}

const initialFormData: FormData = {
  name: "",
  thin: false,
  domain: "",
  adminEmails: [],
};

const OrganizationCreate = () => {
  const { tenantId = "" } = useParams();
  const intl = useIntl();
  const [formData, setFormData] = useState(initialFormData);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const navigate = useNavigate();

  const [createOrganization, isCreatingOrganization] =
    useMutation<OrganizationCreate_createOrganization_Mutation>(
      CREATE_ORGANIZATION_MUTATION
    );

  const isNameValid = !_.isEmpty(formData.name);
  const isFormValid = isNameValid;
  const canCreateOrganization = isFormValid && !isCreatingOrganization;

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setHasSubmitted(true);
      if (!canCreateOrganization) {
        return;
      }
      const input: CreateOrganizationInput = {
        tenantId,
        ...formData,
        domain: formData.domain || null,
      };
      createOrganization({
        variables: { input },
        onCompleted(data, errors) {
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            return setErrorFeedback(errorFeedback);
          }
          const organizationId = data?.createOrganization?.organization.id;
          if (organizationId) {
            navigate({
              route: Route.organizationEdit,
              params: { tenantId, organizationId },
            });
          } else {
            navigate({ route: Route.organizations, params: { tenantId } });
          }
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.OrganizationCreate.createErrorFeedback"
              defaultMessage="Could not create the organization, please try again."
              description="Feedback for unknown create error in the OrganizationCreate page"
            />
          );
        },
        updater(store) {
          const organization = store
            .getRootField("createOrganization")
            .getLinkedRecord("organization");
          const tenant = store.get(tenantId);
          const organizations = tenant?.getLinkedRecords("organizations");
          if (tenant && organizations) {
            tenant.setLinkedRecords(
              [...organizations, organization],
              "organizations"
            );
          }
        },
      });
    },
    [canCreateOrganization, createOrganization, formData, navigate, tenantId]
  );

  return (
    <div className="py-4 px-5">
      <Stack gap={3}>
        <header className="d-flex justify-content-between align-items-center">
          <h2 className="text-muted">
            <FormattedMessage
              id="pages.OrganizationCreate.title"
              defaultMessage="Create Organization"
              description="Title for the OrganizationCreate page"
            />
          </h2>
          <div className="d-flex">
            <Button
              variant="primary"
              type="submit"
              form="organization-form"
              disabled={!canCreateOrganization}
            >
              {isCreatingOrganization && <Spinner size="sm" className="me-2" />}
              <FormattedMessage
                id="pages.OrganizationCreate.form.createButton"
                defaultMessage="Create"
                description="Title for the button to create the organization in the OrganizationCreate page"
              />
            </Button>
          </div>
        </header>
        <Alert
          show={!!errorFeedback}
          variant="danger"
          onClose={() => setErrorFeedback(null)}
          dismissible
        >
          {errorFeedback}
        </Alert>
        <Form id="organization-form" noValidate onSubmit={handleSubmit}>
          <Stack gap={3}>
            <Form.Group controlId="name">
              <Form.Label>
                <FormattedMessage
                  id="pages.OrganizationCreate.nameLabel"
                  defaultMessage="Name"
                  description="Label for the organization name in the OrganizationCreate page"
                />
              </Form.Label>
              <Form.Control
                name="name"
                value={formData.name}
                onChange={(event) =>
                  setFormData({ ...formData, name: event.target.value })
                }
                isValid={hasSubmitted && isNameValid}
                isInvalid={hasSubmitted && !isNameValid}
                placeholder={intl.formatMessage({
                  id: "pages.OrganizationCreate.namePlaceholder",
                  defaultMessage: "Insert a name for the organization",
                  description:
                    "Placeholder for the name field in the OrganizationCreate page",
                })}
              />
              <Form.Control.Feedback type="invalid">
                <FormattedMessage
                  id="pages.OrganizationCreate.form.invalidNameFeedback"
                  defaultMessage="Please provide a valid organization name."
                  description="Feedback for invalid organization name field in the OrganizationCreate page"
                />
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="thin">
              <Form.Label>
                <FormattedMessage
                  id="pages.OrganizationCreate.thinLabel"
                  defaultMessage="Thin"
                  description="Label for the organization thin property in the OrganizationCreate page"
                />
              </Form.Label>
              <Form.Check
                type="checkbox"
                checked={formData.thin}
                onChange={(event) =>
                  setFormData({ ...formData, thin: event.target.checked })
                }
              />
            </Form.Group>
            <Form.Group controlId="domain">
              <Form.Label>
                <FormattedMessage
                  id="pages.OrganizationCreate.domainLabel"
                  defaultMessage="Domain"
                  description="Label for the organization domain in the OrganizationCreate page"
                />
              </Form.Label>
              <Form.Control
                name="domain"
                value={formData.domain}
                onChange={(event) =>
                  setFormData({ ...formData, domain: event.target.value })
                }
                placeholder={intl.formatMessage({
                  id: "pages.OrganizationCreate.domainPlaceholder",
                  defaultMessage:
                    "Insert a domain to reserve for emails that should register to the organization",
                  description:
                    "Placeholder for the domain field in the OrganizationCreate page",
                })}
              />
            </Form.Group>
            <Form.Group controlId="adminEmails">
              <Form.Label>
                <FormattedMessage
                  id="pages.OrganizationCreate.adminEmailsLabel"
                  defaultMessage="Admin Emails"
                  description="Label for the Admin Emails field in the OrganizationCreate page"
                />
              </Form.Label>
              <MultiSelect
                id="adminEmails"
                name="adminEmails"
                selected={formData.adminEmails}
                values={formData.adminEmails}
                getValueId={(email) => email}
                getValueLabel={(email) => email}
                onChange={(adminEmails) =>
                  setFormData({ ...formData, adminEmails })
                }
                onCreateValue={(email) =>
                  setFormData({
                    ...formData,
                    adminEmails: [...formData.adminEmails, email],
                  })
                }
                placeholder={intl.formatMessage({
                  id: "pages.OrganizationCreate.adminEmailsPlaceholder",
                  defaultMessage:
                    "Insert emails of users that will be invited with the role Admin",
                  description:
                    "Placeholder for the Admin Emails field in the OrganizationCreate page",
                })}
              />
            </Form.Group>
          </Stack>
        </Form>
      </Stack>
    </div>
  );
};

export default OrganizationCreate;
