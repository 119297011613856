import React, { Suspense, useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import type { UserFields_getUserFields_Query } from "api/__generated__/UserFields_getUserFields_Query.graphql";
import { useParams } from "react-router";
import { FormattedMessage } from "react-intl";
import Alert from "react-bootstrap/Alert";

import Button from "components/Button";
import UserFieldsTable from "components/UserFieldsTable";
import Result from "components/Result";
import SearchBox from "components/SearchBox";
import Spinner from "components/Spinner";
import Stack from "components/Stack";
import { Link, Route } from "Navigation";

const GET_USER_FIELDS_QUERY = graphql`
  query UserFields_getUserFields_Query($tenantId: ID!) {
    tenant(id: $tenantId) {
      userCustomFields {
        ...UserFieldsTable_UserFieldFragment
      }
    }
  }
`;

type UserFieldsContentProps = {
  getUserFieldsQuery: PreloadedQuery<UserFields_getUserFields_Query>;
};

const UserFieldsContent = ({ getUserFieldsQuery }: UserFieldsContentProps) => {
  const [searchText, setSearchText] = useState("");
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const { tenantId = "" } = useParams();
  const userFieldsData = usePreloadedQuery(
    GET_USER_FIELDS_QUERY,
    getUserFieldsQuery
  );

  const userFields = useMemo(
    () => (userFieldsData.tenant ? userFieldsData.tenant.userCustomFields : []),
    [userFieldsData]
  );

  if (!userFieldsData.tenant) {
    return (
      <Result.NotFound
        title={
          <FormattedMessage
            id="pages.UserFields.tenantNotFound.title"
            defaultMessage="Tenant not found."
            description="Page title for a tenant not found"
          />
        }
      >
        <Link route={Route.tenants}>
          <FormattedMessage
            id="pages.UserFields.tenantNotFound.message"
            defaultMessage="Return to the tenant list."
            description="Page message for a tenant not found"
          />
        </Link>
      </Result.NotFound>
    );
  }

  return (
    <div className="py-4 px-5">
      <header className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="text-muted">
          <FormattedMessage
            id="pages.UserFields.title"
            defaultMessage="User Fields"
            description="Title for the UserFields page"
          />
        </h2>
        {userFields.length > 0 && (
          <Button as={Link} route={Route.userFieldsNew} params={{ tenantId }}>
            <FormattedMessage
              id="pages.UserFields.createUserFieldButton"
              defaultMessage="Create new user field"
              description="Title for the link to create a new user field in UserFields page"
            />
          </Button>
        )}
      </header>
      <main>
        <Alert
          show={!!errorFeedback}
          variant="danger"
          onClose={() => setErrorFeedback(null)}
          dismissible
        >
          {errorFeedback}
        </Alert>
        {userFields.length === 0 ? (
          <div className="mt-5 d-flex justify-content-center">
            <div className="rounded shadow p-5 text-center">
              <h2>
                <FormattedMessage
                  id="pages.UserFields.noUserFieldsTitle"
                  defaultMessage="This space is empty"
                  description="Title for missing user fields message in UserFields page"
                />
              </h2>
              <p className="text-muted">
                <FormattedMessage
                  id="pages.UserFields.noUserFieldsSubtitle"
                  defaultMessage="There are no user fields yet."
                  description="Subtitle for missing user fields message in UserFields page"
                />
              </p>
              <p className="mt-5">
                <Link
                  route={Route.userFieldsNew}
                  params={{ tenantId }}
                  className="btn btn-primary"
                >
                  <FormattedMessage
                    id="pages.UserFields.noUserFieldsButton"
                    defaultMessage="Create new user field"
                    description="Title for the link to create the first user field in UserFields page"
                  />
                </Link>
              </p>
            </div>
          </div>
        ) : (
          <Stack gap={3}>
            <SearchBox value={searchText} onChange={setSearchText} />
            <UserFieldsTable
              userFieldsRef={userFields}
              tenantId={tenantId}
              searchText={searchText}
            />
          </Stack>
        )}
      </main>
    </div>
  );
};

const UserFields = () => {
  const { tenantId = "" } = useParams();
  const [getUserFieldsQuery, getUserFields] =
    useQueryLoader<UserFields_getUserFields_Query>(GET_USER_FIELDS_QUERY);

  useEffect(() => getUserFields({ tenantId }), [getUserFields, tenantId]);

  return (
    <Suspense
      fallback={
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <Spinner />
        </div>
      }
    >
      <ErrorBoundary
        FallbackComponent={(props) => (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <p>
              <FormattedMessage
                id="pages.UserFields.loadingError.feedback"
                defaultMessage="The page couldn't load."
                description="Feedback message on a loading error for the UserFields page"
              />
            </p>
            <Button onClick={props.resetErrorBoundary}>
              <FormattedMessage
                id="pages.UserFields.loadingError.retryButton"
                defaultMessage="Try again"
                description="Retry button on loading error for the UserFields page"
              />
            </Button>
          </div>
        )}
        onReset={() => getUserFields({ tenantId })}
      >
        {getUserFieldsQuery && (
          <UserFieldsContent getUserFieldsQuery={getUserFieldsQuery} />
        )}
      </ErrorBoundary>
    </Suspense>
  );
};

export default UserFields;
