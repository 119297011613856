import React, { useCallback, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay/hooks";
import type {
  CapabilitySetCreate_createCapabilitySet_Mutation,
  CreateCapabilitySetInput,
  Capability,
} from "api/__generated__/CapabilitySetCreate_createCapabilitySet_Mutation.graphql";
import { useParams } from "react-router";
import { FormattedMessage } from "react-intl";
import Alert from "react-bootstrap/Alert";

import Button from "components/Button";
import CapabilitySetForm from "components/CapabilitySetForm";
import Spinner from "components/Spinner";
import Stack from "components/Stack";
import { Route, useNavigate } from "Navigation";

const CREATE_CAPABILITY_SET_MUTATION = graphql`
  mutation CapabilitySetCreate_createCapabilitySet_Mutation(
    $input: CreateCapabilitySetInput!
  ) {
    createCapabilitySet(input: $input) {
      capabilitySet {
        id
        name
        capabilities
      }
    }
  }
`;

interface FormData {
  name: string;
  capabilities: Capability[];
}

const initialFormData: FormData = {
  name: "",
  capabilities: [],
};

const CapabilitySet = () => {
  const { tenantId = "" } = useParams();
  const [draft, setDraft] = useState({
    formData: initialFormData,
    isValid: false,
  });
  const [validated, setValidated] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const navigate = useNavigate();

  const [createCapabilitySet, isCreatingCapabilitySet] =
    useMutation<CapabilitySetCreate_createCapabilitySet_Mutation>(
      CREATE_CAPABILITY_SET_MUTATION
    );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        return setValidated(true);
      }
      const input: CreateCapabilitySetInput = { tenantId, ...draft.formData };
      createCapabilitySet({
        variables: { input },
        onCompleted(data, errors) {
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            return setErrorFeedback(errorFeedback);
          }
          const capabilitySetId = data?.createCapabilitySet?.capabilitySet.id;
          if (capabilitySetId) {
            navigate({
              route: Route.capabilitySetEdit,
              params: { tenantId, capabilitySetId },
            });
          } else {
            navigate({ route: Route.capabilitySets, params: { tenantId } });
          }
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.CapabilitySetCreate.createErrorFeedback"
              defaultMessage="Could not create the capability set, please try again."
              description="Feedback for unknown create error in the CapabilitySetCreate page"
            />
          );
        },
        updater(store, data) {
          const capabilitySetId = data.createCapabilitySet?.capabilitySet?.id;
          if (capabilitySetId) {
            const tenant = store.get(tenantId);
            const capabilitySet = store.get(capabilitySetId);
            const capabilitySets = tenant?.getLinkedRecords("capabilitySets");
            if (tenant && capabilitySets && capabilitySet) {
              tenant.setLinkedRecords(
                [...capabilitySets, capabilitySet],
                "capabilitySets"
              );
            }
          }
        },
      });
    },
    [createCapabilitySet, draft, navigate, tenantId]
  );

  const canCreateCapabilitySet = !isCreatingCapabilitySet;

  return (
    <div className="py-4 px-5">
      <Stack gap={3}>
        <header className="d-flex justify-content-between align-items-center">
          <h2 className="text-muted">
            <FormattedMessage
              id="pages.CapabilitySetCreate.title"
              defaultMessage="Create Capability Set"
              description="Title for the CapabilitySetCreate page"
            />
          </h2>
          <div className="d-flex">
            <Button
              variant="primary"
              type="submit"
              form="capability-set-form"
              disabled={!canCreateCapabilitySet}
            >
              {isCreatingCapabilitySet && (
                <Spinner size="sm" className="me-2" />
              )}
              <FormattedMessage
                id="pages.CapabilitySetCreate.form.createButton"
                defaultMessage="Create"
                description="Title for the button to create the capability set in the CapabilitySetCreate page"
              />
            </Button>
          </div>
        </header>
        <Alert
          show={!!errorFeedback}
          variant="danger"
          onClose={() => setErrorFeedback(null)}
          dismissible
        >
          {errorFeedback}
        </Alert>
        <CapabilitySetForm
          id="capability-set-form"
          value={draft.formData}
          onChange={(formData, isValid) => setDraft({ formData, isValid })}
          onSubmit={handleSubmit}
          noValidate
          validated={validated}
        />
      </Stack>
    </div>
  );
};

export default CapabilitySet;
