/**
 * @generated SignedSource<<152681f949c12ae0f5a06db73d4c9a80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InviteUsersInput = {
  adminEmails: ReadonlyArray<string>;
  organizationId: string;
};
export type Organization_inviteUsers_Mutation$variables = {
  input: InviteUsersInput;
};
export type Organization_inviteUsers_Mutation$data = {
  readonly inviteUsers: {
    readonly organization: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserInvitesTable_userInvites">;
    };
  } | null;
};
export type Organization_inviteUsers_Mutation = {
  response: Organization_inviteUsers_Mutation$data;
  variables: Organization_inviteUsers_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Organization_inviteUsers_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteUsersPayload",
        "kind": "LinkedField",
        "name": "inviteUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserInvitesTable_userInvites"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Organization_inviteUsers_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteUsersPayload",
        "kind": "LinkedField",
        "name": "inviteUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserInvite",
                "kind": "LinkedField",
                "name": "userInvites",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sentTo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0983132062e7dd983695d9f5b53f80f7",
    "id": null,
    "metadata": {},
    "name": "Organization_inviteUsers_Mutation",
    "operationKind": "mutation",
    "text": "mutation Organization_inviteUsers_Mutation(\n  $input: InviteUsersInput!\n) {\n  inviteUsers(input: $input) {\n    organization {\n      id\n      ...UserInvitesTable_userInvites\n    }\n  }\n}\n\nfragment UserInvitesTable_userInvites on Organization {\n  userInvites {\n    sentTo\n    createdAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "cc1205375d62f489f9cfaeee0291fcda";

export default node;
