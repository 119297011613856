import React, { Suspense, useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import type { Organizations_getOrganizations_Query } from "api/__generated__/Organizations_getOrganizations_Query.graphql";
import { useParams } from "react-router";
import { FormattedMessage } from "react-intl";
import Alert from "react-bootstrap/Alert";

import Button from "components/Button";
import OrganizationsTable from "components/OrganizationsTable";
import Result from "components/Result";
import SearchBox from "components/SearchBox";
import Spinner from "components/Spinner";
import Stack from "components/Stack";
import { Link, Route } from "Navigation";

const GET_ORGANIZATIONS_QUERY = graphql`
  query Organizations_getOrganizations_Query($tenantId: ID!) {
    tenant(id: $tenantId) {
      organizations {
        id
        name
        thin
        domain
        capabilitySets {
          name
        }
      }
    }
  }
`;

type OrganizationsContentProps = {
  getOrganizationsQuery: PreloadedQuery<Organizations_getOrganizations_Query>;
};

const OrganizationsContent = ({
  getOrganizationsQuery,
}: OrganizationsContentProps) => {
  const [searchText, setSearchText] = useState("");
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const { tenantId = "" } = useParams();
  const organizationsData = usePreloadedQuery(
    GET_ORGANIZATIONS_QUERY,
    getOrganizationsQuery
  );

  const organizations = useMemo(
    () =>
      organizationsData.tenant
        ? organizationsData.tenant.organizations.map((organization) => ({
            ...organization,
            capabilitySets: organization.capabilitySets.map(
              (capabilitySet) => ({ ...capabilitySet })
            ),
          }))
        : [],
    [organizationsData]
  );

  if (!organizationsData.tenant) {
    return (
      <Result.NotFound
        title={
          <FormattedMessage
            id="pages.Organizations.tenantNotFound.title"
            defaultMessage="Tenant not found."
            description="Page title for a tenant not found"
          />
        }
      >
        <Link route={Route.tenants}>
          <FormattedMessage
            id="pages.Organizations.tenantNotFound.message"
            defaultMessage="Return to the tenant list."
            description="Page message for a tenant not found"
          />
        </Link>
      </Result.NotFound>
    );
  }

  return (
    <div className="py-4 px-5">
      <header className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="text-muted">
          <FormattedMessage
            id="pages.Organizations.title"
            defaultMessage="Organizations"
            description="Title for the Organizations page"
          />
        </h2>
        {organizations.length > 0 && (
          <Button
            as={Link}
            route={Route.organizationsNew}
            params={{ tenantId }}
          >
            <FormattedMessage
              id="pages.Organizations.createOrganizationButton"
              defaultMessage="Create new organization"
              description="Title for the link to create a new organization in Organizations page"
            />
          </Button>
        )}
      </header>
      <main>
        <Alert
          show={!!errorFeedback}
          variant="danger"
          onClose={() => setErrorFeedback(null)}
          dismissible
        >
          {errorFeedback}
        </Alert>
        {organizations.length === 0 ? (
          <div className="mt-5 d-flex justify-content-center">
            <div className="rounded shadow p-5 text-center">
              <h2>
                <FormattedMessage
                  id="pages.Organizations.noOrganizationsTitle"
                  defaultMessage="This space is empty"
                  description="Title for missing organizations message in Organizations page"
                />
              </h2>
              <p className="text-muted">
                <FormattedMessage
                  id="pages.Organizations.noOrganizationsSubtitle"
                  defaultMessage="There are no organizations yet."
                  description="Subtitle for missing organizations message in Organizations page"
                />
              </p>
              <p className="mt-5">
                <Link
                  route={Route.organizationsNew}
                  params={{ tenantId }}
                  className="btn btn-primary"
                >
                  <FormattedMessage
                    id="pages.Organizations.noOrganizationsButton"
                    defaultMessage="Create new organization"
                    description="Title for the link to create the first organization in Organizations page"
                  />
                </Link>
              </p>
            </div>
          </div>
        ) : (
          <Stack gap={3}>
            <SearchBox value={searchText} onChange={setSearchText} />
            <OrganizationsTable
              data={organizations}
              tenantId={tenantId}
              searchText={searchText}
            />
          </Stack>
        )}
      </main>
    </div>
  );
};

const Organizations = () => {
  const { tenantId = "" } = useParams();
  const [getOrganizationsQuery, getOrganizations] =
    useQueryLoader<Organizations_getOrganizations_Query>(
      GET_ORGANIZATIONS_QUERY
    );

  useEffect(() => getOrganizations({ tenantId }), [getOrganizations, tenantId]);

  return (
    <Suspense
      fallback={
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <Spinner />
        </div>
      }
    >
      <ErrorBoundary
        FallbackComponent={(props) => (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <p>
              <FormattedMessage
                id="pages.Organizations.Organizations.feedback"
                defaultMessage="The page couldn't load."
                description="Feedback message on a loading error for the Organizations page"
              />
            </p>
            <Button onClick={props.resetErrorBoundary}>
              <FormattedMessage
                id="pages.Organizations.loadingError.retryButton"
                defaultMessage="Try again"
                description="Retry button on loading error for the Organizations page"
              />
            </Button>
          </div>
        )}
        onReset={() => getOrganizations({ tenantId })}
      >
        {getOrganizationsQuery && (
          <OrganizationsContent getOrganizationsQuery={getOrganizationsQuery} />
        )}
      </ErrorBoundary>
    </Suspense>
  );
};

export default Organizations;
