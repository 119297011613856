import React, { Suspense, useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import type { CapabilitySets_getCapabilitySets_Query } from "api/__generated__/CapabilitySets_getCapabilitySets_Query.graphql";
import { useParams } from "react-router";
import { FormattedMessage } from "react-intl";
import Alert from "react-bootstrap/Alert";

import Button from "components/Button";
import CapabilitySetsTable from "components/CapabilitySetsTable";
import Result from "components/Result";
import SearchBox from "components/SearchBox";
import Spinner from "components/Spinner";
import Stack from "components/Stack";
import { Link, Route } from "Navigation";

const GET_CAPABILITY_SETS_QUERY = graphql`
  query CapabilitySets_getCapabilitySets_Query($tenantId: ID!) {
    tenant(id: $tenantId) {
      capabilitySets {
        id
        name
      }
    }
  }
`;

type CapabilitySetsContentProps = {
  getCapabilitySetsQuery: PreloadedQuery<CapabilitySets_getCapabilitySets_Query>;
};

const CapabilitySetsContent = ({
  getCapabilitySetsQuery,
}: CapabilitySetsContentProps) => {
  const [searchText, setSearchText] = useState("");
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const { tenantId = "" } = useParams();
  const capabilitySetsData = usePreloadedQuery(
    GET_CAPABILITY_SETS_QUERY,
    getCapabilitySetsQuery
  );

  const capabilitySets = useMemo(
    () =>
      capabilitySetsData.tenant
        ? capabilitySetsData.tenant.capabilitySets.map((capabilitySet) => ({
            ...capabilitySet,
          }))
        : [],
    [capabilitySetsData]
  );

  if (!capabilitySetsData.tenant) {
    return (
      <Result.NotFound
        title={
          <FormattedMessage
            id="pages.CapabilitySets.tenantNotFound.title"
            defaultMessage="Tenant not found."
            description="Page title for a tenant not found"
          />
        }
      >
        <Link route={Route.tenants}>
          <FormattedMessage
            id="pages.CapabilitySets.tenantNotFound.message"
            defaultMessage="Return to the tenant list."
            description="Page message for a tenant not found"
          />
        </Link>
      </Result.NotFound>
    );
  }

  return (
    <div className="py-4 px-5">
      <header className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="text-muted">
          <FormattedMessage
            id="pages.CapabilitySets.title"
            defaultMessage="Capability Sets"
            description="Title for the CapabilitySets page"
          />
        </h2>
        {capabilitySets.length > 0 && (
          <Button
            as={Link}
            route={Route.capabilitySetsNew}
            params={{ tenantId }}
          >
            <FormattedMessage
              id="pages.CapabilitySets.createCapabilitySetButton"
              defaultMessage="Create new capability set"
              description="Title for the link to create a new capability set in CapabilitySets page"
            />
          </Button>
        )}
      </header>
      <main>
        <Alert
          show={!!errorFeedback}
          variant="danger"
          onClose={() => setErrorFeedback(null)}
          dismissible
        >
          {errorFeedback}
        </Alert>
        {capabilitySets.length === 0 ? (
          <div className="mt-5 d-flex justify-content-center">
            <div className="rounded shadow p-5 text-center">
              <h2>
                <FormattedMessage
                  id="pages.CapabilitySets.noCapabilitySetsTitle"
                  defaultMessage="This space is empty"
                  description="Title for missing capability sets message in CapabilitySets page"
                />
              </h2>
              <p className="text-muted">
                <FormattedMessage
                  id="pages.CapabilitySets.noCapabilitySetsSubtitle"
                  defaultMessage="There are no capability sets yet."
                  description="Subtitle for missing capability sets message in CapabilitySets page"
                />
              </p>
              <p className="mt-5">
                <Link
                  route={Route.capabilitySetsNew}
                  params={{ tenantId }}
                  className="btn btn-primary"
                >
                  <FormattedMessage
                    id="pages.CapabilitySets.noCapabilitySetsButton"
                    defaultMessage="Create new capability set"
                    description="Title for the link to create the first capability set in CapabilitySets page"
                  />
                </Link>
              </p>
            </div>
          </div>
        ) : (
          <Stack gap={3}>
            <SearchBox value={searchText} onChange={setSearchText} />
            <CapabilitySetsTable
              data={capabilitySets}
              tenantId={tenantId}
              searchText={searchText}
            />
          </Stack>
        )}
      </main>
    </div>
  );
};

const CapabilitySets = () => {
  const { tenantId = "" } = useParams();
  const [getCapabilitySetsQuery, getCapabilitySets] =
    useQueryLoader<CapabilitySets_getCapabilitySets_Query>(
      GET_CAPABILITY_SETS_QUERY
    );

  useEffect(
    () => getCapabilitySets({ tenantId }),
    [getCapabilitySets, tenantId]
  );

  return (
    <Suspense
      fallback={
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <Spinner />
        </div>
      }
    >
      <ErrorBoundary
        FallbackComponent={(props) => (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <p>
              <FormattedMessage
                id="pages.CapabilitySets.CapabilitySets.feedback"
                defaultMessage="The page couldn't load."
                description="Feedback message on a loading error for the CapabilitySets page"
              />
            </p>
            <Button onClick={props.resetErrorBoundary}>
              <FormattedMessage
                id="pages.CapabilitySets.loadingError.retryButton"
                defaultMessage="Try again"
                description="Retry button on loading error for the CapabilitySets page"
              />
            </Button>
          </div>
        )}
        onReset={() => getCapabilitySets({ tenantId })}
      >
        {getCapabilitySetsQuery && (
          <CapabilitySetsContent
            getCapabilitySetsQuery={getCapabilitySetsQuery}
          />
        )}
      </ErrorBoundary>
    </Suspense>
  );
};

export default CapabilitySets;
