/**
 * @generated SignedSource<<6ebc1adb07c475143db1ad87d7feb0df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteCapabilitySetInput = {
  capabilitySetId: string;
};
export type CapabilitySet_deleteCapabilitySet_Mutation$variables = {
  input: DeleteCapabilitySetInput;
};
export type CapabilitySet_deleteCapabilitySet_Mutation$data = {
  readonly deleteCapabilitySet: {
    readonly capabilitySet: {
      readonly id: string;
    };
  } | null;
};
export type CapabilitySet_deleteCapabilitySet_Mutation = {
  response: CapabilitySet_deleteCapabilitySet_Mutation$data;
  variables: CapabilitySet_deleteCapabilitySet_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteCapabilitySetPayload",
    "kind": "LinkedField",
    "name": "deleteCapabilitySet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CapabilitySet",
        "kind": "LinkedField",
        "name": "capabilitySet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CapabilitySet_deleteCapabilitySet_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CapabilitySet_deleteCapabilitySet_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "287c3ce0ef0a3874925e623ae7b7b4c9",
    "id": null,
    "metadata": {},
    "name": "CapabilitySet_deleteCapabilitySet_Mutation",
    "operationKind": "mutation",
    "text": "mutation CapabilitySet_deleteCapabilitySet_Mutation(\n  $input: DeleteCapabilitySetInput!\n) {\n  deleteCapabilitySet(input: $input) {\n    capabilitySet {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e2c5e1c1c880d15a2b95a87d227c8d72";

export default node;
