/**
 * @generated SignedSource<<c812689deac8d2a57744661168819acf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TenantCreate_getClusters_Query$variables = {};
export type TenantCreate_getClusters_Query$data = {
  readonly clusters: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly provider: string;
    readonly zone: string;
  }>;
};
export type TenantCreate_getClusters_Query = {
  response: TenantCreate_getClusters_Query$data;
  variables: TenantCreate_getClusters_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Cluster",
    "kind": "LinkedField",
    "name": "clusters",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provider",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "zone",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TenantCreate_getClusters_Query",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TenantCreate_getClusters_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "78fe2a14d10f349b89e60fec65079299",
    "id": null,
    "metadata": {},
    "name": "TenantCreate_getClusters_Query",
    "operationKind": "query",
    "text": "query TenantCreate_getClusters_Query {\n  clusters {\n    id\n    name\n    provider\n    zone\n  }\n}\n"
  }
};
})();

(node as any).hash = "71ef6d086cfa2b90567b1cf8ce7f31ec";

export default node;
