import { useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import type {
  Column as RTColumn,
  Row as RTRow,
  HeaderGroup as RTHeaderGroup,
  UsePaginationInstanceProps,
  UsePaginationState,
  UseGlobalFiltersInstanceProps,
  UseSortByColumnOptions,
  UseSortByColumnProps,
  UseSortByInstanceProps,
  UseSortByState,
  UseTableOptions,
  TableInstance as RTTableInstance,
  TableState as RTTableState,
} from "react-table";
import RBTable from "react-bootstrap/Table";
import _ from "lodash";

import Icon from "components/Icon";
import TablePagination from "components/TablePagination";

type TableState<T extends Object> = RTTableState<T> &
  UsePaginationState<T> &
  UseSortByState<T>;

type TableInstance<T extends Object> = RTTableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseGlobalFiltersInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: TableState<T>;
  };

type HeaderGroup<T extends Object> = RTHeaderGroup<T> & UseSortByColumnProps<T>;

type Row<T extends Object> = RTRow<T>;

type Column<T extends Object> = RTColumn<T> & UseSortByColumnOptions<T>;

function defaultSearchFunction<T extends Object>(
  rows: Row<T>[],
  columnIds: string[],
  globalFilterValue: string
) {
  return rows.filter((row) => {
    if (globalFilterValue) {
      return _.values(row).some(
        (value) => _.isString(value) && value.includes(globalFilterValue)
      );
    } else {
      return rows;
    }
  });
}

// Let the table remove the filter if the string is empty
defaultSearchFunction.autoRemove = (value: string) => !value;

type SortDirectionIndicatorProps = {
  className?: string;
  descending: boolean;
};

const SortDirectionIndicator = ({
  className,
  descending,
}: SortDirectionIndicatorProps) => (
  <span className={className}>
    {descending ? <Icon icon="arrowDown" /> : <Icon icon="arrowUp" />}
  </span>
);

type TableProps<T extends Object> = {
  columns: Column<T>[];
  data: T[];
  className?: string;
  maxPageRows?: number;
  searchFunction?: (
    rows: Row<T>[],
    columnIds: string[],
    globalFilterValue: any
  ) => Row<T>[];
  searchText?: string;
  initialState?: UseTableOptions<T>["initialState"];
};

const Table = <T extends Object>({
  columns,
  data,
  className,
  initialState,
  maxPageRows = 10,
  searchFunction,
  searchText = "",
}: TableProps<T>) => {
  const tableParams = {
    columns,
    data,
    initialState,
    filterTypes: {
      text: searchFunction ? searchFunction : defaultSearchFunction,
    },
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    // pagination
    page,
    pageOptions,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },

    // filter
    setGlobalFilter,
  } = useTable(
    tableParams,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstance<T>;

  useEffect(() => {
    if (pageSize !== maxPageRows) {
      setPageSize(maxPageRows);
    }
  }, [pageSize, maxPageRows, setPageSize]);

  useEffect(() => {
    setGlobalFilter(searchText);
  }, [setGlobalFilter, searchText]);

  return (
    <div className={className}>
      <RBTable {...getTableProps()} responsive hover>
        <thead className="border-top">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const col = column as HeaderGroup<T>;
                return (
                  <th {...col.getHeaderProps(col.getSortByToggleProps())}>
                    {col.render("Header")}
                    {col.isSorted && (
                      <SortDirectionIndicator
                        className="ms-2"
                        descending={col.isSortedDesc || false}
                      />
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="border-top-0">
          {page.map((row) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </RBTable>
      <TablePagination
        totalPages={pageOptions.length}
        activePage={pageIndex}
        onPageChange={gotoPage}
      />
    </div>
  );
};

export type { Column, Row };

export default Table;
