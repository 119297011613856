import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ErrorBoundary } from "react-error-boundary";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import type { UserField_getUserField_Query } from "api/__generated__/UserField_getUserField_Query.graphql";
import type {
  UserField_updateUserField_Mutation,
  UpdateUserCustomFieldInput,
} from "api/__generated__/UserField_updateUserField_Mutation.graphql";
import type { UserField_deleteUserField_Mutation } from "api/__generated__/UserField_deleteUserField_Mutation.graphql";
import { useParams } from "react-router";
import { FormattedMessage } from "react-intl";
import Alert from "react-bootstrap/Alert";
import _ from "lodash";

import Button from "components/Button";
import UserFieldForm from "components/UserFieldForm";
import DeleteModal from "components/DeleteModal";
import OptionsMenu from "components/OptionsMenu";
import Spinner from "components/Spinner";
import Result from "components/Result";
import Stack from "components/Stack";
import { Link, Route, useNavigate } from "Navigation";

const GET_USER_FIELD_QUERY = graphql`
  query UserField_getUserField_Query($id: ID!) {
    userCustomField(id: $id) {
      name
      schema
    }
  }
`;

const UPDATE_USER_FIELD_MUTATION = graphql`
  mutation UserField_updateUserField_Mutation(
    $input: UpdateUserCustomFieldInput!
  ) {
    updateUserCustomField(input: $input) {
      userCustomField {
        id
        name
        schema
      }
    }
  }
`;

const DELETE_USER_FIELD_MUTATION = graphql`
  mutation UserField_deleteUserField_Mutation(
    $input: DeleteUserCustomFieldInput!
  ) {
    deleteUserCustomField(input: $input) {
      userCustomField {
        id
      }
    }
  }
`;

interface FormData {
  name: string;
  schema: string | null;
}

const initialFormData: FormData = {
  name: "",
  schema: null,
};

type UserFieldContentProps = {
  getUserFieldQuery: PreloadedQuery<UserField_getUserField_Query>;
};

const UserFieldContent = ({ getUserFieldQuery }: UserFieldContentProps) => {
  const { userFieldId = "", tenantId = "" } = useParams();
  const [draft, setDraft] = useState({
    formData: initialFormData,
    isValid: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const navigate = useNavigate();

  const userFieldData = usePreloadedQuery(
    GET_USER_FIELD_QUERY,
    getUserFieldQuery
  );

  const userField = useMemo(
    () =>
      userFieldData.userCustomField && {
        ...userFieldData.userCustomField,
      },
    [userFieldData.userCustomField]
  );

  const [updateUserField, isUpdatingUserField] =
    useMutation<UserField_updateUserField_Mutation>(UPDATE_USER_FIELD_MUTATION);

  const [deleteUserField, isDeletingUserField] =
    useMutation<UserField_deleteUserField_Mutation>(DELETE_USER_FIELD_MUTATION);

  const handleSubmit = useCallback(() => {
    const input: UpdateUserCustomFieldInput = {
      userCustomFieldId: userFieldId,
      ...draft.formData,
    };
    updateUserField({
      variables: { input },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          return setErrorFeedback(errorFeedback);
        }
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.UserField.updateErrorFeedback"
            defaultMessage="Could not update the user field, please try again."
            description="Feedback for unknown update error in the UserField page"
          />
        );
      },
    });
  }, [updateUserField, draft, userFieldId]);

  const handleDeleteUserField = useCallback(() => {
    deleteUserField({
      variables: { input: { userCustomFieldId: userFieldId } },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          setShowDeleteModal(false);
          return setErrorFeedback(errorFeedback);
        }
        navigate({ route: Route.userFields, params: { tenantId } });
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.UserField.deleteErrorFeedback"
            defaultMessage="Could not delete the user field, please try again."
            description="Feedback for unknown deletion error in the UserField page"
          />
        );
        setShowDeleteModal(false);
      },
      updater(store) {
        const userFieldId = store
          .getRootField("deleteUserCustomField")
          .getLinkedRecord("userCustomField")
          .getDataID();
        const tenant = store.get(tenantId);
        const userFields = tenant?.getLinkedRecords("userCustomFields");
        if (tenant && userFields) {
          tenant.setLinkedRecords(
            userFields.filter(
              (userField) => userField.getDataID() !== userFieldId
            ),
            "userCustomFields"
          );
        }
        store.delete(userFieldId);
      },
    });
  }, [deleteUserField, navigate, userFieldId, tenantId]);

  useEffect(() => {
    if (userField) {
      setDraft({ formData: userField, isValid: true });
    }
  }, [userField]);

  if (!userField) {
    return (
      <Result.NotFound
        title={
          <FormattedMessage
            id="pages.UserField.userFieldNotFound.title"
            defaultMessage="User field not found."
            description="Page title for a user field not found"
          />
        }
      >
        <Link route={Route.userFields} params={{ tenantId }}>
          <FormattedMessage
            id="pages.UserField.userFieldNotFound.message"
            defaultMessage="Return to the user field list."
            description="Page message for a user field not found"
          />
        </Link>
      </Result.NotFound>
    );
  }

  const canUpdateUserField =
    !_.isEqual(draft.formData, userField) && !isUpdatingUserField;

  return (
    <div className="py-4 px-5">
      <Stack gap={3}>
        <header className="d-flex justify-content-between align-items-center">
          <h2 className="text-muted">{userField.name}</h2>
          <div className="d-flex">
            <Button
              variant="primary"
              type="submit"
              form="user-field-form"
              disabled={!canUpdateUserField}
            >
              {isUpdatingUserField && <Spinner size="sm" className="me-2" />}
              <FormattedMessage
                id="pages.UserField.form.updateButton"
                defaultMessage="Update"
                description="Title for the button to update the user field in the UserField page"
              />
            </Button>
            <OptionsMenu alignEnd>
              <OptionsMenu.Item
                className="text-danger"
                onClick={() => setShowDeleteModal(true)}
              >
                <FormattedMessage
                  id="pages.UserField.deleteButton"
                  defaultMessage="Delete"
                  description="Button to delete the user field in the UserField page"
                />
              </OptionsMenu.Item>
            </OptionsMenu>
          </div>
        </header>
        <Alert
          show={!!errorFeedback}
          variant="danger"
          onClose={() => setErrorFeedback(null)}
          dismissible
        >
          {errorFeedback}
        </Alert>
        <UserFieldForm
          id="user-field-form"
          value={draft.formData}
          onChange={(formData, isValid) => setDraft({ formData, isValid })}
          onSubmit={handleSubmit}
        />
      </Stack>
      {showDeleteModal && (
        <DeleteModal
          confirmText={userField.name}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteUserField}
          isDeleting={isDeletingUserField}
          title={
            <FormattedMessage
              id="pages.UserField.deleteModal.title"
              defaultMessage="Delete User Field"
              description="Title for the confirmation modal to delete a user field"
            />
          }
        >
          <p>
            <FormattedMessage
              id="pages.UserField.deleteModal.description"
              defaultMessage="This action cannot be undone. This will permanently delete the user field <bold>{userField}</bold>."
              description="Description for the confirmation modal to delete a user field"
              values={{
                userField: userField.name,
                bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </DeleteModal>
      )}
    </div>
  );
};

const UserField = () => {
  const { userFieldId = "" } = useParams();
  const [getUserFieldQuery, getUserCustomField] =
    useQueryLoader<UserField_getUserField_Query>(GET_USER_FIELD_QUERY);

  useEffect(
    () => getUserCustomField({ id: userFieldId }),
    [getUserCustomField, userFieldId]
  );

  return (
    <Suspense
      fallback={
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <Spinner />
        </div>
      }
    >
      <ErrorBoundary
        FallbackComponent={(props) => (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <p>
              <FormattedMessage
                id="pages.UserField.loadingError.feedback"
                defaultMessage="The page couldn't load."
                description="Feedback message on a loading error for the UserField page"
              />
            </p>
            <Button onClick={props.resetErrorBoundary}>
              <FormattedMessage
                id="pages.UserField.loadingError.retryButton"
                defaultMessage="Try again"
                description="Retry button on loading error for the UserField page"
              />
            </Button>
          </div>
        )}
        onReset={() => getUserCustomField({ id: userFieldId })}
      >
        {getUserFieldQuery && (
          <UserFieldContent getUserFieldQuery={getUserFieldQuery} />
        )}
      </ErrorBoundary>
    </Suspense>
  );
};

export default UserField;
