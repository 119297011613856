/**
 * @generated SignedSource<<81ae02f9b8f9bee1ce9949d66861cbc9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserInvitesTable_userInvites$data = {
  readonly userInvites: ReadonlyArray<{
    readonly createdAt: string;
    readonly sentTo: string;
  }>;
  readonly " $fragmentType": "UserInvitesTable_userInvites";
};
export type UserInvitesTable_userInvites$key = {
  readonly " $data"?: UserInvitesTable_userInvites$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserInvitesTable_userInvites">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserInvitesTable_userInvites",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserInvite",
      "kind": "LinkedField",
      "name": "userInvites",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sentTo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "26a82a361a48fe7cd043cfba8def71a4";

export default node;
