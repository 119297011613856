import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ErrorBoundary } from "react-error-boundary";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import type { Organization_getOrganization_Query } from "api/__generated__/Organization_getOrganization_Query.graphql";
import type { CapabilitySets_getCapabilitySets_Query } from "api/__generated__/CapabilitySets_getCapabilitySets_Query.graphql";
import type {
  Organization_updateOrganization_Mutation,
  UpdateOrganizationInput,
} from "api/__generated__/Organization_updateOrganization_Mutation.graphql";
import type { Organization_inviteUsers_Mutation } from "api/__generated__/Organization_inviteUsers_Mutation.graphql";
import type { Organization_deleteUserInvites_Mutation } from "api/__generated__/Organization_deleteUserInvites_Mutation.graphql";
import { useParams } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import _ from "lodash";

import DeleteModal from "components/DeleteModal";
import MultiSelect from "components/MultiSelect";
import Spinner from "components/Spinner";
import Result from "components/Result";
import SectionCard from "components/SectionCard";
import UserInvitesTable from "components/UserInvitesTable";
import { Link, Route } from "Navigation";

const GET_ORGANIZATION_QUERY = graphql`
  query Organization_getOrganization_Query($id: ID!) {
    organization(id: $id) {
      name
      thin
      domain
      capabilitySets {
        id
        name
      }
      ...UserInvitesTable_userInvites
    }
  }
`;

const UPDATE_ORGANIZATION_MUTATION = graphql`
  mutation Organization_updateOrganization_Mutation(
    $input: UpdateOrganizationInput!
  ) {
    updateOrganization(input: $input) {
      organization {
        id
        name
        thin
        domain
        capabilitySets {
          id
          name
        }
        ...UserInvitesTable_userInvites
      }
    }
  }
`;

const INVITE_USERS_MUTATION = graphql`
  mutation Organization_inviteUsers_Mutation($input: InviteUsersInput!) {
    inviteUsers(input: $input) {
      organization {
        id
        ...UserInvitesTable_userInvites
      }
    }
  }
`;

const DELETE_USER_INVITES_MUTATION = graphql`
  mutation Organization_deleteUserInvites_Mutation(
    $input: DeleteUserInvitesInput!
  ) {
    deleteUserInvites(input: $input) {
      organization {
        id
        ...UserInvitesTable_userInvites
      }
    }
  }
`;

const GET_CAPABILITY_SETS_QUERY = graphql`
  query Organization_getCapabilitySets_Query($tenantId: ID!) {
    tenant(id: $tenantId) {
      capabilitySets {
        id
        name
      }
    }
  }
`;

type InviteUsersFormProps = {
  isInvitingUsers?: boolean;
  onInviteUser: (emails: string[]) => void;
};

const InviteUsersForm = ({
  isInvitingUsers,
  onInviteUser,
}: InviteUsersFormProps) => {
  const [emails, setEmails] = useState<string[]>([]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onInviteUser(emails);
      setEmails([]);
    },
    [onInviteUser, emails]
  );

  const canInviteUsers = emails.length > 0 && !isInvitingUsers;

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Stack gap={3}>
        <Form.Group controlId="emails">
          <Form.Label>
            <FormattedMessage
              id="pages.Organization.UserInvitesSection.emailsLabel"
              defaultMessage="Invite Users by Email"
            />
          </Form.Label>
          <MultiSelect
            id="emails"
            name="emails"
            selected={emails}
            values={emails}
            getValueId={(email) => email}
            getValueLabel={(email) => email}
            onChange={setEmails}
            onCreateValue={(email) => setEmails([...emails, email])}
            placeholder={
              <FormattedMessage
                id="pages.Organization.UserInvitesSection.adminEmailsPlaceholder"
                defaultMessage="Insert emails of users that will be invited with the role Admin"
              />
            }
          />
        </Form.Group>
        <div className="d-flex justify-content-end align-items-center">
          <Button variant="primary" type="submit" disabled={!canInviteUsers}>
            {isInvitingUsers && <Spinner size="sm" className="me-2" />}
            <FormattedMessage
              id="pages.Organization.UserInvitesSection.inviteButton"
              defaultMessage="Invite"
            />
          </Button>
        </div>
      </Stack>
    </Form>
  );
};

type CapabilitySet = {
  id: string;
  name: string;
};

interface FormData {
  name: string;
  thin: boolean;
  domain: string | null;
  capabilitySets: CapabilitySet[];
}

const initialFormData: FormData = {
  name: "",
  thin: false,
  domain: null,
  capabilitySets: [],
};

type OrganizationContentProps = {
  getCapabilitySetsQuery: PreloadedQuery<CapabilitySets_getCapabilitySets_Query>;
  getOrganizationQuery: PreloadedQuery<Organization_getOrganization_Query>;
};

const OrganizationContent = ({
  getCapabilitySetsQuery,
  getOrganizationQuery,
}: OrganizationContentProps) => {
  const { organizationId = "", tenantId = "" } = useParams();
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [validated, setValidated] = useState(false);
  const [updateOrganizationFeedback, setUpdateOrganizationFeedback] =
    useState<React.ReactNode>(null);
  const [userInvitesToDelete, setUserInvitesToDelete] = useState<string[]>([]);
  const [deleteUserInvitesFeedback, setDeleteUserInvitesFeedback] =
    useState<React.ReactNode>(null);
  const [inviteUsersFeedback, setInviteUsersFeedback] =
    useState<React.ReactNode>(null);
  const intl = useIntl();

  const capabilitySetsData = usePreloadedQuery(
    GET_CAPABILITY_SETS_QUERY,
    getCapabilitySetsQuery
  );

  const organizationData = usePreloadedQuery(
    GET_ORGANIZATION_QUERY,
    getOrganizationQuery
  );

  const capabilitySets = useMemo(
    () =>
      capabilitySetsData.tenant
        ? capabilitySetsData.tenant.capabilitySets.map((capabilitySet) => ({
            ...capabilitySet,
          }))
        : [],
    [capabilitySetsData]
  );

  const organization = useMemo(
    () =>
      organizationData.organization && {
        ...organizationData.organization,
        capabilitySets: organizationData.organization.capabilitySets.map(
          (capabilitySet) => ({
            ...capabilitySet,
          })
        ),
      },
    [organizationData.organization]
  );

  const [updateOrganization, isUpdatingOrganization] =
    useMutation<Organization_updateOrganization_Mutation>(
      UPDATE_ORGANIZATION_MUTATION
    );

  const [inviteUsers, isInvitingUsers] =
    useMutation<Organization_inviteUsers_Mutation>(INVITE_USERS_MUTATION);

  const [deleteUserInvites, isDeletingUserInvites] =
    useMutation<Organization_deleteUserInvites_Mutation>(
      DELETE_USER_INVITES_MUTATION
    );

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => {
      const target = event.target;
      const field = target.id;
      if (field === "domain") {
        const value = target.value || null;
        setFormData((data) => ({ ...data, [field]: value }));
      } else {
        const value =
          target.type === "checkbox" ? target.checked : target.value;
        setFormData((data) => ({ ...data, [field]: value }));
      }
    }, []);

  const handleUpdateOrganization: React.FormEventHandler<HTMLFormElement> =
    useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          return setValidated(true);
        }
        const input: UpdateOrganizationInput = {
          organizationId,
          name: formData.name,
          thin: formData.thin,
          domain: formData.domain || null,
          capabilitySetIds: formData.capabilitySets.map(
            (capabilitySet) => capabilitySet.id
          ),
        };
        updateOrganization({
          variables: { input },
          onCompleted(data, errors) {
            if (errors) {
              const updateOrganizationFeedback = errors
                .map((error) => error.message)
                .join(". \n");
              return setUpdateOrganizationFeedback(updateOrganizationFeedback);
            }
          },
          onError(error) {
            setUpdateOrganizationFeedback(
              <FormattedMessage
                id="pages.Organization.updateErrorFeedback"
                defaultMessage="Could not update the organization, please try again."
                description="Feedback for unknown update error in the Organization page"
              />
            );
          },
        });
      },
      [updateOrganization, formData, organizationId]
    );

  const handleDeleteUserInvites = useCallback(
    (emails: string[]) => {
      deleteUserInvites({
        variables: { input: { organizationId, emails } },
        onCompleted(data, errors) {
          setUserInvitesToDelete([]);
          if (errors) {
            const deleteUserInvitesFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            setDeleteUserInvitesFeedback(deleteUserInvitesFeedback);
          }
        },
        onError(error) {
          setDeleteUserInvitesFeedback(
            <FormattedMessage
              id="pages.Organization.deleteUserInvitesErrorFeedback"
              defaultMessage="Could not delete the user invites, please try again."
              description="Feedback for unknown user invites deletion error in the Organization page"
            />
          );
        },
      });
    },
    [deleteUserInvites, organizationId]
  );

  const handleInviteUsers = useCallback(
    (adminEmails: string[]) => {
      inviteUsers({
        variables: { input: { organizationId, adminEmails } },
        onCompleted(data, errors) {
          if (errors) {
            const inviteUsersFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            setInviteUsersFeedback(inviteUsersFeedback);
          }
        },
        onError(error) {
          setInviteUsersFeedback(
            <FormattedMessage
              id="pages.Organization.inviteUsersErrorFeedback"
              defaultMessage="Could not create the user invites, please try again."
              description="Feedback for unknown user invites creation error in the Organization page"
            />
          );
        },
      });
    },
    [inviteUsers, organizationId]
  );

  useEffect(() => {
    if (organization) {
      setFormData(organization);
    }
  }, [organization]);

  if (!organization) {
    return (
      <Result.NotFound
        title={
          <FormattedMessage
            id="pages.Organization.organizationNotFound.title"
            defaultMessage="Organization not found."
            description="Page title for an organization not found"
          />
        }
      >
        <Link route={Route.organizations} params={{ tenantId }}>
          <FormattedMessage
            id="pages.Organization.organizationNotFound.message"
            defaultMessage="Return to the organization list."
            description="Page message for a organization not found"
          />
        </Link>
      </Result.NotFound>
    );
  }

  if (!capabilitySetsData.tenant) {
    return (
      <Result.NotFound
        title={
          <FormattedMessage
            id="pages.Organization.tenantNotFound.title"
            defaultMessage="Tenant not found."
            description="Page title for a tenant not found"
          />
        }
      >
        <Link route={Route.tenants}>
          <FormattedMessage
            id="pages.Organization.tenantNotFound.message"
            defaultMessage="Return to the tenant list."
            description="Page message for a tenant not found"
          />
        </Link>
      </Result.NotFound>
    );
  }

  const canUpdateOrganization =
    !_.isEqual(formData, organization) && !isUpdatingOrganization;

  return (
    <div className="py-4 px-5">
      <Stack gap={3}>
        <header className="d-flex justify-content-between align-items-center">
          <h2 className="text-muted">{organization.name}</h2>
        </header>
        <SectionCard
          title={
            <FormattedMessage
              id="pages.Organization.organizationInfoSection.title"
              defaultMessage="Organization Info"
            />
          }
        >
          <Form
            noValidate
            validated={validated}
            onSubmit={handleUpdateOrganization}
          >
            <Stack gap={3}>
              <Alert
                show={!!updateOrganizationFeedback}
                variant="danger"
                onClose={() => setUpdateOrganizationFeedback(null)}
                dismissible
              >
                {updateOrganizationFeedback}
              </Alert>
              <Form.Group as={Row} controlId="name">
                <Form.Label column sm="2">
                  <FormattedMessage
                    id="pages.Organization.nameLabel"
                    defaultMessage="Name"
                    description="Label for the organization name in the Organization page"
                  />
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    placeholder={intl.formatMessage({
                      id: "pages.Organization.form.namePlaceholder",
                      defaultMessage: "Name",
                      description:
                        "Placeholder for the organization name field in the Organization page",
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    <FormattedMessage
                      id="pages.Organization.form.invalidNameFeedback"
                      defaultMessage="Please provide a valid organization name."
                      description="Feedback for invalid organization name field in the Organization page"
                    />
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="thin">
                <Form.Label column sm="2">
                  <FormattedMessage
                    id="pages.Organization.thinLabel"
                    defaultMessage="Thin"
                    description="Label for the organization thin property in the Organization page"
                  />
                </Form.Label>
                <Col sm="10">
                  <Form.Check
                    type="checkbox"
                    checked={formData.thin}
                    onChange={handleInputChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="domain">
                <Form.Label column sm="2">
                  <FormattedMessage
                    id="pages.Organization.domainLabel"
                    defaultMessage="Domain"
                    description="Label for the organization domain in the Organization page"
                  />
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="domain"
                    value={formData.domain || ""}
                    onChange={handleInputChange}
                    placeholder={intl.formatMessage({
                      id: "pages.Organization.form.domainPlaceholder",
                      defaultMessage:
                        "Insert a domain to reserve for emails that should register to the organization",
                      description:
                        "Placeholder for the organization domain field in the Organization page",
                    })}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="capabilitySets">
                <Form.Label column sm="2">
                  <FormattedMessage
                    id="pages.Organization.capabilitySetsLabel"
                    defaultMessage="Capability Sets"
                    description="Label for the capabilitySets field in the Organization page"
                  />
                </Form.Label>
                <Col sm="10">
                  <MultiSelect
                    id="capabilitySets"
                    name="capabilitySets"
                    selected={formData.capabilitySets}
                    values={capabilitySets}
                    getValueId={(capabilitySet) => capabilitySet.id}
                    getValueLabel={(capabilitySet) => capabilitySet.name}
                    onChange={(capabilitySets) =>
                      setFormData((data) => ({ ...data, capabilitySets }))
                    }
                  />
                </Col>
              </Form.Group>
              <div className="d-flex justify-content-end align-items-center">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!canUpdateOrganization}
                >
                  {isUpdatingOrganization && (
                    <Spinner size="sm" className="me-2" />
                  )}
                  <FormattedMessage
                    id="pages.Organization.form.updateButton"
                    defaultMessage="Update"
                    description="Title for the button to update the organization in the Organization page"
                  />
                </Button>
              </div>
            </Stack>
          </Form>
        </SectionCard>
        <SectionCard
          title={
            <FormattedMessage
              id="pages.Organization.userInvitesSection.title"
              defaultMessage="User Invites"
            />
          }
        >
          <Stack gap={3}>
            <Alert
              show={!!deleteUserInvitesFeedback}
              variant="danger"
              onClose={() => setDeleteUserInvitesFeedback(null)}
              dismissible
            >
              {deleteUserInvitesFeedback}
            </Alert>
            <UserInvitesTable
              userInvitesRef={organization}
              onDeleteUserInvites={setUserInvitesToDelete}
              tenantId={tenantId}
            />
            <Alert
              show={!!inviteUsersFeedback}
              variant="danger"
              onClose={() => setInviteUsersFeedback(null)}
              dismissible
            >
              {inviteUsersFeedback}
            </Alert>
            <InviteUsersForm
              onInviteUser={handleInviteUsers}
              isInvitingUsers={isInvitingUsers}
            />
          </Stack>
        </SectionCard>
      </Stack>
      {userInvitesToDelete.length > 0 && (
        <DeleteModal
          confirmText={userInvitesToDelete[0]}
          onCancel={() => setUserInvitesToDelete([])}
          onConfirm={() => handleDeleteUserInvites(userInvitesToDelete)}
          isDeleting={isDeletingUserInvites}
          title={
            <FormattedMessage
              id="pages.Organization.deleteUserInvitesModal.title"
              defaultMessage="Delete User Invites"
            />
          }
        >
          <p>
            <FormattedMessage
              id="pages.Organization.deleteUserInvitesModal.description"
              defaultMessage="This action cannot be undone. This will permanently delete the user invites for <bold>{emails}</bold>."
              values={{
                emails: userInvitesToDelete.join(", "),
                bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </DeleteModal>
      )}
    </div>
  );
};

const Organization = () => {
  const { organizationId = "", tenantId = "" } = useParams();
  const [getOrganizationQuery, getOrganization] =
    useQueryLoader<Organization_getOrganization_Query>(GET_ORGANIZATION_QUERY);
  const [getCapabilitySetsQuery, getCapabilitySets] =
    useQueryLoader<CapabilitySets_getCapabilitySets_Query>(
      GET_CAPABILITY_SETS_QUERY
    );

  useEffect(
    () => getCapabilitySets({ tenantId }),
    [getCapabilitySets, tenantId]
  );

  useEffect(
    () => getOrganization({ id: organizationId }),
    [getOrganization, organizationId]
  );

  return (
    <Suspense
      fallback={
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <Spinner />
        </div>
      }
    >
      <ErrorBoundary
        FallbackComponent={(props) => (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <p>
              <FormattedMessage
                id="pages.Organization.Organization.feedback"
                defaultMessage="The page couldn't load."
                description="Feedback message on a loading error for the Organization page"
              />
            </p>
            <Button onClick={props.resetErrorBoundary}>
              <FormattedMessage
                id="pages.Organization.loadingError.retryButton"
                defaultMessage="Try again"
                description="Retry button on loading error for the Organization page"
              />
            </Button>
          </div>
        )}
        onReset={() => getOrganization({ id: organizationId })}
      >
        {getCapabilitySetsQuery && getOrganizationQuery && (
          <OrganizationContent
            getCapabilitySetsQuery={getCapabilitySetsQuery}
            getOrganizationQuery={getOrganizationQuery}
          />
        )}
      </ErrorBoundary>
    </Suspense>
  );
};

export default Organization;
