import React, { useCallback, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Form from "react-bootstrap/Form";
import Ajv from "ajv";

import Stack from "components/Stack";

const ajv = new Ajv();

type FormData = {
  name: string;
  schema: string | null;
};

type Props = {
  id?: string;
  readOnly?: boolean;
  value: FormData;
  onChange?: (data: FormData, isValid: boolean) => void;
  onSubmit?: () => void;
};

const UserFieldForm = ({
  id,
  readOnly = false,
  value,
  onChange,
  onSubmit,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const intl = useIntl();

  const isNameValid = value.name !== "";

  const isSchemaValid = useMemo(() => {
    if (!value.schema) {
      return true;
    }
    try {
      const jsonSchema = JSON.parse(value.schema);
      return !!ajv.validateSchema(jsonSchema);
    } catch {
      return false;
    }
  }, [value.schema]);

  const isFormValid = isNameValid && isSchemaValid;

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        const target = event.target;
        let fieldValue: boolean | string | null =
          target.type === "checkbox" ? target.checked : target.value;
        const field = target.id;
        if (field === "schema") {
          fieldValue = fieldValue || null;
        }
        const newValue = { ...value, [field]: fieldValue };
        const isFormValid = !!formRef.current?.checkValidity();
        onChange && onChange(newValue, isFormValid);
      },
      [value, onChange]
    );

  const handleSubmit: React.FormEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setHasSubmitted(true);
      if (isFormValid) {
        onSubmit && onSubmit();
      }
    },
    [isFormValid, onSubmit]
  );

  return (
    <Form
      id={id}
      ref={formRef}
      onSubmit={handleSubmit}
      noValidate
      data-testid="user-field-form"
    >
      <Stack gap={3}>
        <Form.Group controlId="name">
          <Form.Label>
            <FormattedMessage
              id="components.UserFieldForm.nameLabel"
              defaultMessage="Name"
              description="Label for the name field in the user field form"
            />
          </Form.Label>
          <Form.Control
            value={value.name}
            onChange={handleInputChange}
            readOnly={readOnly}
            plaintext={readOnly}
            required
            placeholder={intl.formatMessage({
              id: "components.UserFieldForm.namePlaceholder",
              defaultMessage: "Type in the name of the user field",
              description:
                "Placeholder for the name field in the user field form",
            })}
            name="name"
            data-testid="user-field-form-name"
            isValid={hasSubmitted && isNameValid}
            isInvalid={hasSubmitted && !isNameValid}
          />
          <Form.Control.Feedback type="invalid">
            <FormattedMessage
              id="pages.UserFieldForm.invalidNameFeedback"
              defaultMessage="Please provide a valid name."
              description="Feedback for invalid name field in the UserFieldForm page"
            />
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="schema">
          <Form.Label>
            <FormattedMessage
              id="components.UserFieldForm.schemaLabel"
              defaultMessage="Schema"
              description="Label for the schema field in the user field form"
            />
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={10}
            value={value.schema || ""}
            onChange={handleInputChange}
            readOnly={readOnly}
            plaintext={readOnly}
            placeholder={intl.formatMessage({
              id: "components.UserFieldForm.schemaPlaceholder",
              defaultMessage: "Type in the schema of the user field",
              description:
                "Placeholder for the schema field in the user field form",
            })}
            name="schema"
            data-testid="user-field-form-schema"
            isValid={hasSubmitted && isSchemaValid}
            isInvalid={hasSubmitted && !isSchemaValid}
            spellCheck={false}
            autoComplete="off"
            autoCorrect="off"
          />
          <Form.Control.Feedback type="invalid">
            <FormattedMessage
              id="pages.UserFieldForm.invalidSchemaFeedback"
              defaultMessage="Please provide a valid schema."
              description="Feedback for invalid schema field in the UserFieldForm page"
            />
          </Form.Control.Feedback>
        </Form.Group>
      </Stack>
    </Form>
  );
};

export default UserFieldForm;
