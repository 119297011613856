/**
 * @generated SignedSource<<944e1cca6e407576564391cf1001c50d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateTenantInput = {
  authConfig?: TenantAuthConfigInput | null;
  design?: DesignSystemInput | null;
  registrationSteps?: ReadonlyArray<RegistrationStepInput> | null;
  tenantId: string;
};
export type TenantAuthConfigInput = {
  recaptchaSecretKey?: string | null;
  recaptchaSiteKey?: string | null;
};
export type DesignSystemInput = {
  logo?: string | null;
  logoFile?: File | null;
  theme?: string | null;
  themeFile?: File | null;
};
export type RegistrationStepInput = {
  applicationId: string;
  name: string;
};
export type Tenant_updateTenant_Mutation$variables = {
  input: UpdateTenantInput;
};
export type Tenant_updateTenant_Mutation$data = {
  readonly updateTenant: {
    readonly tenant: {
      readonly authConfig: {
        readonly recaptchaSecretKey: string | null;
        readonly recaptchaSiteKey: string | null;
      };
      readonly design: {
        readonly logo: string | null;
        readonly theme: string | null;
      };
      readonly id: string;
    };
  } | null;
};
export type Tenant_updateTenant_Mutation = {
  response: Tenant_updateTenant_Mutation$data;
  variables: Tenant_updateTenant_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateTenantPayload",
    "kind": "LinkedField",
    "name": "updateTenant",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantAuthConfig",
            "kind": "LinkedField",
            "name": "authConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "recaptchaSecretKey",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "recaptchaSiteKey",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DesignSystem",
            "kind": "LinkedField",
            "name": "design",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "logo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "theme",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Tenant_updateTenant_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Tenant_updateTenant_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f233ce3d6b3285c0cff9f365d61cf291",
    "id": null,
    "metadata": {},
    "name": "Tenant_updateTenant_Mutation",
    "operationKind": "mutation",
    "text": "mutation Tenant_updateTenant_Mutation(\n  $input: UpdateTenantInput!\n) {\n  updateTenant(input: $input) {\n    tenant {\n      id\n      authConfig {\n        recaptchaSecretKey\n        recaptchaSiteKey\n      }\n      design {\n        logo\n        theme\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cbabf382a90e992bf59af282bc81ff0a";

export default node;
