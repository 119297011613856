/**
 * @generated SignedSource<<0f6b9d4185761cb504a0bb1e6715798b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserCustomFieldInput = {
  name: string;
  schema?: string | null;
  userCustomFieldId: string;
};
export type UserField_updateUserField_Mutation$variables = {
  input: UpdateUserCustomFieldInput;
};
export type UserField_updateUserField_Mutation$data = {
  readonly updateUserCustomField: {
    readonly userCustomField: {
      readonly id: string;
      readonly name: string;
      readonly schema: string | null;
    };
  } | null;
};
export type UserField_updateUserField_Mutation = {
  response: UserField_updateUserField_Mutation$data;
  variables: UserField_updateUserField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserCustomFieldPayload",
    "kind": "LinkedField",
    "name": "updateUserCustomField",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserCustomField",
        "kind": "LinkedField",
        "name": "userCustomField",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schema",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserField_updateUserField_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserField_updateUserField_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cd6c16d275f76a375b98c188d0a5d517",
    "id": null,
    "metadata": {},
    "name": "UserField_updateUserField_Mutation",
    "operationKind": "mutation",
    "text": "mutation UserField_updateUserField_Mutation(\n  $input: UpdateUserCustomFieldInput!\n) {\n  updateUserCustomField(input: $input) {\n    userCustomField {\n      id\n      name\n      schema\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "135def821b082548f1a267d7e71db356";

export default node;
