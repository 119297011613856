import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faAngleDown,
  faAngleLeft,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faBuilding,
  faEllipsisV,
  faEye,
  faEyeSlash,
  faLanguage,
  faPlus,
  faRocket,
  faSearch,
  faTachometerAlt,
  faTimes,
  faTrash,
  faUser,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";

const icons = {
  applications: faRocket,
  arrowDown: faArrowDown,
  arrowUp: faArrowUp,
  caretDown: faAngleDown,
  caretLeft: faAngleLeft,
  caretUp: faAngleUp,
  close: faTimes,
  customFields: faAddressCard,
  dashboard: faTachometerAlt,
  delete: faTrash,
  hide: faEyeSlash,
  language: faLanguage,
  moreOptionsMenu: faEllipsisV,
  operations: faUserFriends,
  organizations: faBuilding,
  plus: faPlus,
  profile: faUser,
  search: faSearch,
  show: faEye,
} as const;

type FontAwesomeIconProps = React.ComponentProps<typeof FontAwesomeIcon>;

type Props = Omit<FontAwesomeIconProps, "icon"> & {
  icon: keyof typeof icons;
};

const Icon = ({ icon, ...restProps }: Props) => {
  return <FontAwesomeIcon {...restProps} icon={icons[icon]} />;
};

export default Icon;
