/**
 * @generated SignedSource<<d5b3d27ad28148085c988e5149b31601>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserFields_getUserFields_Query$variables = {
  tenantId: string;
};
export type UserFields_getUserFields_Query$data = {
  readonly tenant: {
    readonly userCustomFields: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"UserFieldsTable_UserFieldFragment">;
    }>;
  } | null;
};
export type UserFields_getUserFields_Query = {
  response: UserFields_getUserFields_Query$data;
  variables: UserFields_getUserFields_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserFields_getUserFields_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserCustomField",
            "kind": "LinkedField",
            "name": "userCustomFields",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserFieldsTable_UserFieldFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserFields_getUserFields_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserCustomField",
            "kind": "LinkedField",
            "name": "userCustomFields",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b7b6634f8a9abf84814eec3533ff59d5",
    "id": null,
    "metadata": {},
    "name": "UserFields_getUserFields_Query",
    "operationKind": "query",
    "text": "query UserFields_getUserFields_Query(\n  $tenantId: ID!\n) {\n  tenant(id: $tenantId) {\n    userCustomFields {\n      ...UserFieldsTable_UserFieldFragment\n      id\n    }\n    id\n  }\n}\n\nfragment UserFieldsTable_UserFieldFragment on UserCustomField {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "5a3b07e85c77624458a010f0c9a5cd77";

export default node;
