/**
 * @generated SignedSource<<39e0c70af10b152262c3f860836e5c50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateOrganizationInput = {
  capabilitySetIds?: ReadonlyArray<string> | null;
  domain?: string | null;
  name?: string | null;
  organizationId: string;
  thin?: boolean | null;
};
export type Organization_updateOrganization_Mutation$variables = {
  input: UpdateOrganizationInput;
};
export type Organization_updateOrganization_Mutation$data = {
  readonly updateOrganization: {
    readonly organization: {
      readonly capabilitySets: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
      readonly domain: string | null;
      readonly id: string;
      readonly name: string;
      readonly thin: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"UserInvitesTable_userInvites">;
    };
  } | null;
};
export type Organization_updateOrganization_Mutation = {
  response: Organization_updateOrganization_Mutation$data;
  variables: Organization_updateOrganization_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thin",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domain",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "CapabilitySet",
  "kind": "LinkedField",
  "name": "capabilitySets",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Organization_updateOrganization_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationPayload",
        "kind": "LinkedField",
        "name": "updateOrganization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserInvitesTable_userInvites"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Organization_updateOrganization_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationPayload",
        "kind": "LinkedField",
        "name": "updateOrganization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserInvite",
                "kind": "LinkedField",
                "name": "userInvites",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sentTo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fafcc2d8dcebd906f90093bf00c68ccf",
    "id": null,
    "metadata": {},
    "name": "Organization_updateOrganization_Mutation",
    "operationKind": "mutation",
    "text": "mutation Organization_updateOrganization_Mutation(\n  $input: UpdateOrganizationInput!\n) {\n  updateOrganization(input: $input) {\n    organization {\n      id\n      name\n      thin\n      domain\n      capabilitySets {\n        id\n        name\n      }\n      ...UserInvitesTable_userInvites\n    }\n  }\n}\n\nfragment UserInvitesTable_userInvites on Organization {\n  userInvites {\n    sentTo\n    createdAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "6d1cba9279759735ed3a58f6a8ce0337";

export default node;
