import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ErrorBoundary } from "react-error-boundary";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import type {
  CapabilitySet_getCapabilitySet_Query,
  Capability,
} from "api/__generated__/CapabilitySet_getCapabilitySet_Query.graphql";
import type {
  CapabilitySet_updateCapabilitySet_Mutation,
  UpdateCapabilitySetInput,
} from "api/__generated__/CapabilitySet_updateCapabilitySet_Mutation.graphql";
import type { CapabilitySet_deleteCapabilitySet_Mutation } from "api/__generated__/CapabilitySet_deleteCapabilitySet_Mutation.graphql";
import { useParams } from "react-router";
import { FormattedMessage } from "react-intl";
import Alert from "react-bootstrap/Alert";
import _ from "lodash";

import Button from "components/Button";
import CapabilitySetForm from "components/CapabilitySetForm";
import DeleteModal from "components/DeleteModal";
import OptionsMenu from "components/OptionsMenu";
import Spinner from "components/Spinner";
import Result from "components/Result";
import Stack from "components/Stack";
import { Link, Route, useNavigate } from "Navigation";

const GET_CAPABILITY_SET_QUERY = graphql`
  query CapabilitySet_getCapabilitySet_Query($id: ID!) {
    capabilitySet(id: $id) {
      name
      capabilities
    }
  }
`;

const UPDATE_CAPABILITY_SET_MUTATION = graphql`
  mutation CapabilitySet_updateCapabilitySet_Mutation(
    $input: UpdateCapabilitySetInput!
  ) {
    updateCapabilitySet(input: $input) {
      capabilitySet {
        id
        name
        capabilities
      }
    }
  }
`;

const DELETE_CAPABILITY_SET_MUTATION = graphql`
  mutation CapabilitySet_deleteCapabilitySet_Mutation(
    $input: DeleteCapabilitySetInput!
  ) {
    deleteCapabilitySet(input: $input) {
      capabilitySet {
        id
      }
    }
  }
`;

interface FormData {
  name: string;
  capabilities: Capability[];
}

const initialFormData: FormData = {
  name: "",
  capabilities: [],
};

type CapabilitySetContentProps = {
  getCapabilitySetQuery: PreloadedQuery<CapabilitySet_getCapabilitySet_Query>;
};

const CapabilitySetContent = ({
  getCapabilitySetQuery,
}: CapabilitySetContentProps) => {
  const { capabilitySetId = "", tenantId = "" } = useParams();
  const [draft, setDraft] = useState({
    formData: initialFormData,
    isValid: false,
  });
  const [validated, setValidated] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const navigate = useNavigate();

  const capabilitySetData = usePreloadedQuery(
    GET_CAPABILITY_SET_QUERY,
    getCapabilitySetQuery
  );

  const capabilitySet = useMemo(
    () =>
      capabilitySetData.capabilitySet && {
        ...capabilitySetData.capabilitySet,
        capabilities: capabilitySetData.capabilitySet.capabilities.slice(),
      },
    [capabilitySetData.capabilitySet]
  );

  const [updateCapabilitySet, isUpdatingCapabilitySet] =
    useMutation<CapabilitySet_updateCapabilitySet_Mutation>(
      UPDATE_CAPABILITY_SET_MUTATION
    );

  const [deleteCapabilitySet, isDeletingCapabilitySet] =
    useMutation<CapabilitySet_deleteCapabilitySet_Mutation>(
      DELETE_CAPABILITY_SET_MUTATION
    );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        return setValidated(true);
      }
      const input: UpdateCapabilitySetInput = {
        capabilitySetId,
        ...draft.formData,
      };
      updateCapabilitySet({
        variables: { input },
        onCompleted(data, errors) {
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            return setErrorFeedback(errorFeedback);
          }
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.CapabilitySet.updateErrorFeedback"
              defaultMessage="Could not update the capability set, please try again."
              description="Feedback for unknown update error in the CapabilitySet page"
            />
          );
        },
      });
    },
    [updateCapabilitySet, draft, capabilitySetId]
  );

  const handleDeleteCapabilitySet = useCallback(async () => {
    deleteCapabilitySet({
      variables: { input: { capabilitySetId } },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          setShowDeleteModal(false);
          return setErrorFeedback(errorFeedback);
        }
        navigate({ route: Route.capabilitySets, params: { tenantId } });
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.CapabilitySet.deleteErrorFeedback"
            defaultMessage="Could not delete the capability set, please try again."
            description="Feedback for unknown deletion error in the CapabilitySet page"
          />
        );
        setShowDeleteModal(false);
      },
      updater(store, data) {
        const capabilitySetId = data?.deleteCapabilitySet?.capabilitySet.id;
        if (capabilitySetId) {
          const tenant = store.get(tenantId);
          const capabilitySets = tenant?.getLinkedRecords("capabilitySets");
          if (tenant && capabilitySets) {
            tenant.setLinkedRecords(
              capabilitySets.filter(
                (capabilitySet) => capabilitySet.getDataID() !== capabilitySetId
              ),
              "capabilitySets"
            );
          }
        }
      },
    });
  }, [deleteCapabilitySet, navigate, capabilitySetId, tenantId]);

  useEffect(() => {
    if (capabilitySet) {
      setDraft({ formData: capabilitySet, isValid: true });
    }
  }, [capabilitySet]);

  if (!capabilitySet) {
    return (
      <Result.NotFound
        title={
          <FormattedMessage
            id="pages.CapabilitySet.capabilitySetNotFound.title"
            defaultMessage="Capability set not found."
            description="Page title for a capability set not found"
          />
        }
      >
        <Link route={Route.capabilitySets} params={{ tenantId }}>
          <FormattedMessage
            id="pages.CapabilitySet.capabilitySetNotFound.message"
            defaultMessage="Return to the capability set list."
            description="Page message for a capability set not found"
          />
        </Link>
      </Result.NotFound>
    );
  }

  const canUpdateCapabilitySet =
    !_.isEqual(draft.formData, capabilitySet) && !isUpdatingCapabilitySet;

  return (
    <div className="py-4 px-5">
      <Stack gap={3}>
        <header className="d-flex justify-content-between align-items-center">
          <h2 className="text-muted">{capabilitySet.name}</h2>
          <div className="d-flex">
            <Button
              variant="primary"
              type="submit"
              form="capability-set-form"
              disabled={!canUpdateCapabilitySet}
            >
              {isUpdatingCapabilitySet && (
                <Spinner size="sm" className="me-2" />
              )}
              <FormattedMessage
                id="pages.CapabilitySet.form.updateButton"
                defaultMessage="Update"
                description="Title for the button to update the capability set in the CapabilitySet page"
              />
            </Button>
            <OptionsMenu alignEnd>
              <OptionsMenu.Item
                className="text-danger"
                onClick={() => setShowDeleteModal(true)}
              >
                <FormattedMessage
                  id="pages.CapabilitySet.deleteButton"
                  defaultMessage="Delete"
                  description="Button to delete the capability set in the CapabilitySet page"
                />
              </OptionsMenu.Item>
            </OptionsMenu>
          </div>
        </header>
        <Alert
          show={!!errorFeedback}
          variant="danger"
          onClose={() => setErrorFeedback(null)}
          dismissible
        >
          {errorFeedback}
        </Alert>
        <CapabilitySetForm
          id="capability-set-form"
          value={draft.formData}
          onChange={(formData, isValid) => setDraft({ formData, isValid })}
          onSubmit={handleSubmit}
          noValidate
          validated={validated}
        />
      </Stack>
      {showDeleteModal && (
        <DeleteModal
          confirmText={capabilitySet.name}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteCapabilitySet}
          isDeleting={isDeletingCapabilitySet}
          title={
            <FormattedMessage
              id="pages.CapabilitySet.deleteModal.title"
              defaultMessage="Delete Capability Set"
              description="Title for the confirmation modal to delete a capability set"
            />
          }
        >
          <p>
            <FormattedMessage
              id="pages.CapabilitySet.deleteModal.description"
              defaultMessage="This action cannot be undone. This will permanently delete the capability set <bold>{capabilitySet}</bold>."
              description="Description for the confirmation modal to delete a capability set"
              values={{
                capabilitySet: capabilitySet.name,
                bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </DeleteModal>
      )}
    </div>
  );
};

const CapabilitySet = () => {
  const { capabilitySetId = "" } = useParams();
  const [getCapabilitySetQuery, getCapabilitySet] =
    useQueryLoader<CapabilitySet_getCapabilitySet_Query>(
      GET_CAPABILITY_SET_QUERY
    );

  useEffect(
    () => getCapabilitySet({ id: capabilitySetId }),
    [getCapabilitySet, capabilitySetId]
  );

  return (
    <Suspense
      fallback={
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <Spinner />
        </div>
      }
    >
      <ErrorBoundary
        FallbackComponent={(props) => (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <p>
              <FormattedMessage
                id="pages.CapabilitySet.CapabilitySet.feedback"
                defaultMessage="The page couldn't load."
                description="Feedback message on a loading error for the CapabilitySet page"
              />
            </p>
            <Button onClick={props.resetErrorBoundary}>
              <FormattedMessage
                id="pages.CapabilitySet.loadingError.retryButton"
                defaultMessage="Try again"
                description="Retry button on loading error for the CapabilitySet page"
              />
            </Button>
          </div>
        )}
        onReset={() => getCapabilitySet({ id: capabilitySetId })}
      >
        {getCapabilitySetQuery && (
          <CapabilitySetContent getCapabilitySetQuery={getCapabilitySetQuery} />
        )}
      </ErrorBoundary>
    </Suspense>
  );
};

export default CapabilitySet;
