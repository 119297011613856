/**
 * @generated SignedSource<<7feeaefc162fb8e4b34b86bdcd206b4f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteUserCustomFieldInput = {
  userCustomFieldId: string;
};
export type UserField_deleteUserField_Mutation$variables = {
  input: DeleteUserCustomFieldInput;
};
export type UserField_deleteUserField_Mutation$data = {
  readonly deleteUserCustomField: {
    readonly userCustomField: {
      readonly id: string;
    };
  } | null;
};
export type UserField_deleteUserField_Mutation = {
  response: UserField_deleteUserField_Mutation$data;
  variables: UserField_deleteUserField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteUserCustomFieldPayload",
    "kind": "LinkedField",
    "name": "deleteUserCustomField",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserCustomField",
        "kind": "LinkedField",
        "name": "userCustomField",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserField_deleteUserField_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserField_deleteUserField_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "92d1afaf2ac5c2278244f4cd59ba45f9",
    "id": null,
    "metadata": {},
    "name": "UserField_deleteUserField_Mutation",
    "operationKind": "mutation",
    "text": "mutation UserField_deleteUserField_Mutation(\n  $input: DeleteUserCustomFieldInput!\n) {\n  deleteUserCustomField(input: $input) {\n    userCustomField {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e5f2897f686a38a454b3ecb624c113dc";

export default node;
